.product-specs__title {
  font-size: $h4-font-size;
  margin-bottom: 1rem;

  button {
    float: right;
    padding: 0;
    margin: 0;
    border: none;
    background: none;

    i {
      color: $gray-light;
      font-size: $font-size-base;
      margin-right: 0.7rem;
    }
  }
}

.flame-retardancy--pdf {
  &:hover {
    font-weight: $font-weight-bold;

    i {
      font-weight: $font-weight-bold;
    }
  }
}

.flame-retardancy--pdf-global {
  color: $very-dark-cyan;

  a {
    color: $very-dark-cyan;

    &:hover {
      text-decoration: none;
    }
  }
}

.flame-retardancy--pdf-marine {
  color: $prussian-blue;

  a {
    color: $prussian-blue;

    &:hover {
      text-decoration: none;
    }
  }
}
