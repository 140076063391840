@use "sass:math";

@mixin no-gutters($prefix){
  .no-gutters#{$prefix} {
    margin-right: 0;
    margin-left: 0;

    > .col,
    > [class*="col-"] {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

$sizes: 'extra-small', 'small', 'regular', 'medium', 'large', 'extra-large';

@mixin gutters($prefix: ''){
  @each $size in $sizes {
    .#{$size}-gutters#{$prefix} {
      margin-right: math.div(map_get($grid-gutter-widths, $size), -2);
      margin-left: math.div(map_get($grid-gutter-widths, $size), -2);

      > .col,
      > [class*="col-"] {
        padding-right: map_get($grid-gutter-widths, $size) * 0.5;
        padding-left: map_get($grid-gutter-widths, $size) * 0.5;
      }
    }

    .mb-#{$size}-gutter#{$prefix} {
      margin-bottom: map_get($grid-gutter-widths, $size);
    }
  }
}

@mixin gutter-margins($prefix: ''){
  $sides: (
          t: 'top',
          r: 'right',
          b: 'bottom',
          l: 'left'
  );
  @each $size in $sizes {
    @each $side in map_keys($sides){
      .m#{$side}#{$prefix}-gutter-#{$size} {
        margin-#{map_get($sides, $side)}: map_get($grid-gutter-widths, $size);
      }
    }
  }
}

@mixin no-gutters($prefix){
  .no-gutters#{$prefix} {
    margin-right: 0;
    margin-left: 0;

    > .col,
    > [class*="col-"] {
      padding-right: 0;
      padding-left: 0;
    }
  }
}