.testimonial-slider--prev-arrow {
  position: absolute;
  left: -40px; 
  cursor: pointer;
}

.testimonial-slider--next-arrow {
  position: absolute;
  right: -60px; 
  cursor: pointer;
}

.testimonial-slider--container {
  height: 100%;
  overflow-x: hidden;
}

.custom-class-width {
  max-width: 96% !important;
}

.relative {
  position: relative;
}

.transition-all {
  transition: all 0.3s ease-in-out;
}

.hover-w-50:hover {
  width: 150% !important;
  height: 100%;
}

.aspect-square {
  padding-bottom: 0;
}

.aspect-5-1 {
  padding-bottom: 0;
}

.position-absolute {
  position: absolute;
}

.bottom-0 {
  bottom: 0;
}

.bottom-6 {
  bottom: 1.5rem;
}

.bg-gray-400 {
  background-color: #818181;
}

.bg-gray-300 {
  background-color: #cfcece;
}

.testimonial-slider--dots {
  height: 5px;
}

.bg-opacity-50 {
  background-color: rgba(192, 192, 192, 0.5);
}

.font-weight-bold {
  font-weight: bold;
}

.rounded-2xl {
  border-radius: 1rem;
}

.pointer-events-none {
  pointer-events: none;
}

.z-index-40 {
  z-index: 40;
}

.z-index-10 {
  z-index: 10;
}

.testimonial-slider--dots {
  border: none !important;
  outline: none !important;
  margin-top: 0.8rem;
}

.custom_class_slides_transition_testimonials {
  display: flex !important;
  flex-direction: row !important;
  max-width: 100%;
  transition: all 0.3s;
}

.custom_class_slides_transition_testimonials .flicking-camera {
  display: flex !important;

  @media (min-width: 768px) {
    transform: translate(0px) !important;
    transition: all 0.3s;
    width: 100%;
    height: 100%;
  }
}