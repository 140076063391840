.characteristic-image--slider > .slick-prev::before {
  content: "" !important;
}

.characteristic-image--slider > .slick-next::before {
  content: "" !important;
}

.next-arrow-container, .prev-arrow-container {
  padding: 8px 13px;
  background-color: #052e60;
  position: relative;
}

.slick-prev {
  z-index: 1;
}

.prev-arrow-container {
  left: 10px;
}

.next-arrow-container {
  right: 16px;
}

.next-arrow-img, .prev-arrow-img {
  filter: brightness(0) invert(1);
}

.prev-arrow-img {
  transform: scaleX(-1);
}

.individual-image--container {
  height: 320px;
  border-radius: 40px;
}

.characteristic-image--slider > .slick-dots li button:before {
  color: #585858;
  opacity: 0.5;
}

.characteristic-image--slider > .slick-dots li.slick-active button:before {
  color: rgb(0, 0, 0);
  opacity: 1;
}

.antimicrobial-biocide-comfort-img {
  position: relative;
  left: -40px;
}