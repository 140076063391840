.product-card {
  border: 1px solid $product-card-border-color;
  background-color: $white;

  &.product-card--selected {
    border: 1px solid $product-card-active-border-color;
  }
}

.product-card__info {
  padding: 0 ($grid-small-gutter-width * 0.5);
  font-size: $font-size-xs;
  position: relative;

  p,
  button {
    padding: ($grid-small-gutter-width * 0.5) 0;
  }

  p {
    margin: 0;
    color: $gray-light;
  }

  i.fa {
    position: absolute;
    right: 0;
    top: 50%;
    width: $product-card-check-icon-radius;
    height: $product-card-check-icon-radius;
    margin-top: $product-card-check-icon-radius * -.5;
    font-size: $product-card-check-icon-radius;
  }
}