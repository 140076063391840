.main--hero-slide {
  display: block !important;
  position: relative;
  height: 512px;
  background-color: $skeleton-background;
  background-position: center center;
  background-size: cover;

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.1);
  }
}

// mockey patch for styles of react-slick
.slick-active {
  z-index: 10;
  position: relative;
}
