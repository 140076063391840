.product-specs-accordion {
  
}

.product-specs-accordion__title {
  border: $table-border-width solid $table-border-color;
  margin: 0;
  color: $gray-light;
  padding: 1rem $table-cell-padding;
  font-size: $h5-font-size;

  i {
    float: right;
  }
}

.product-specs-accordion__collapse {
  border: $table-border-width solid $table-border-color;
  border-top: none;
}

.product-specs-table {
  margin: 0;
  table-layout: fixed;
}