.higher-purpose--container {
  height: fit-content;
  color: white;
  background-color: #00575F;
}

.higher-purpose--body-section {
  height: fit-content;
}

.higher-purpose--container-accordions {
  height: fit-content;
}