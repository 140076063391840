@include media-breakpoint-up(md) {
  .product-card-name {
    height: 70px; // 2 lines of text, including <a/>'s padding

    h2 {
      a {
        display: block;
        font-size: $font-size-base;
        color: black;
        padding: 1rem;
      }
    }
  }

  .product-card-name--in-compare {
    height: 64px; // 4 lines of text, including <a/>'s padding

    h2 {
      a {
        padding: 8px;
        font-size: $font-size-sm * .85;
      }
    }
  }
}