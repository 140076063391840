.sustainable-reports--slider > .carousel-control-prev {
  background-image: none !important;
}

.sustainable-reports--slider > .carousel-control-next {
  background-image: none !important;
}

.sustainable--slider-next-arrow, .sustainable--slider-prev-arrow {
  filter: brightness(0) invert(1);
}

.custom-indicators-sustainable-slider > .carousel-indicators > button {
  background-color: gray;
}

.custom-indicators-sustainable-slider > .carousel-indicators > .active {
  background-color: white;
}

.carousel-title-sustainbility-reports > .sustainability-report-title-2023 {
  display: block;
}

.carousel-desc-sustainability-reports > .sustainability-report-2023-desc {
  font-size: 1rem;
}