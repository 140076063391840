@include media-breakpoint-down(sm) {
  .products-page {
    > .container {
      padding: 0;
    }
  }

  .filter-mobile-button {
    display: block;
  }
}