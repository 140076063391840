@include media-breakpoint-up(lg) {
  .hero-title {
    height: 45vh;
  }

  .hero-title__content {
    .container {
      padding-right: $sub-hero-shape-width-lg * .8;
      padding-bottom: $sub-hero-shape-height-lg * .15;
    }
  }

  .hero-title-heading {
    font-size: $h1-font-size * 1.3;
  }
}
