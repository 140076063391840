.pattern-added-toast {

}

.pattern-added-toast__image {
  width: 90px;

  img {
    max-width: 90px;
  }
}