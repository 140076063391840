.vacant-details__return-link {
  color: $gray-light;
}

.vacant-details__name {
  font-weight: $font-weight-light;

  .vacant-details__city {
    font-family: $font-family-base;
    font-size: 60%;
    font-weight: 400;
  }
}