.store-finder-box--container {
  background-color: black;
  color: white;
  height: $store-finder-box-header-height;
  padding: $store-finder-box-header-height * 0.15 $grid-gutter-width-half;
  font-weight: bold;

  img {
    height: $store-finder-box-header-height * 0.7;
    width: auto;
    margin-right: $grid-gutter-width-half;
  }
}

.store-finder-box__content {
  background-color: $cyan-darker;

  p {
    color: $white;
  }
}

.store-finder-box--btn {
  width: $btn-go-back-size;
  height: $btn-go-back-size;
  padding: 0.4375rem 0;
  border-radius: 0.9375rem;
  text-align: center;
  font-size: 0.75rem;
  line-height: 1.42857;
  color: $white;

  &:hover {
    color: $black;
    background-color: $white;
  }
}