@include media-breakpoint-down(sm) {
  .header-auth-box {
    > .row {
      > [class*="col"] {
        &:first-child {
          padding-bottom: 1rem;
          margin-bottom: 1rem;
          border-bottom: 1px solid $auth-box-separator-color;
        }
      }
    }
  }
}
