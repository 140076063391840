// TODO fix this double selector problem here and make it a re-usable classname
.job-application-form,
.no-vacancies-form {
  label.file-input-label {
    display: inline-block;
    width: 5em;
    padding: 0 1em;
    text-align: right;
  }

  input#attachCV {
    position: absolute;
    left: 0;
    top: 0;
  }

  /* Hide the file input using
  opacity */
  [type=file], [type=file]:disabled {
    position: absolute;
    filter: alpha(opacity=0);
    opacity: 0;
  }

  input[type=file],
  [type=file] + label.file-input-label {
    //border: 1px solid #CCC;
    //border-radius: 3px;
    text-align: left;
    padding: 7px;
    margin: 0;
    left: 0;
    position: relative;
    display: block;
    width: 100%;
  }

  [type=file] + label.file-input-label {
    text-align: center;
  }
}
