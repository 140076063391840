@include media-breakpoint-up(xl) {
  .home-featured-project-see-all-button {
    padding-top: 14px;
  }

  .home-featured-project {
    height: 330px;
  }

  .text-size-xl-h2 {
    font-size: 1rem;
  }

  .text-size-xl-h3 {
    font-size: 1rem;
  }

  .text-size-xl-h4 {
    font-size: 1rem;
  }

  .home-featured-project-image {
  
    img {
      width: 100%;
      height: 110%;
    }
  }
}

