@include media-breakpoint-up(lg) {
  .header-subinstances {
    height: $header-height-subinstance-usa;
  }

  .header-subinstances-menu-button {
    display: block;
    height: 2.5rem;
    padding: 0 1rem;
    margin: 0 1rem;
    color: $white;
    background-color: transparent;
    border: none;
    text-align: left;
    line-height: 2.5rem;

    &:hover,
    &:focus,
    &:active {
      outline: none;
      text-decoration: none;
      box-shadow: none;
      color: $white;
    }

    // &:nth-child(1) {
    //   margin-left: 0;
    // }
  }

  .separator {
    &::after {
      content: "|";
      color: $white;
    }
  }
}
