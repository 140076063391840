// @font-face {
//   /* font-family: 'Gramatika';
//   src: url('../public/fonts/Gramatika-Regular.woff2') format('woff2'),
//     url('../public/fonts/Gramatika-Regular.woff') format('woff');
//   font-weight: 400;
//   font-style: normal; */
// }

// @font-face {
//   /* font-family: 'Gramatika';
//   src: url('../public/fonts/Gramatika-Bold.woff2') format('woff2'),
//     url('../public/fonts/Gramatika-Bold.woff') format('woff');
//   font-weight: 600;
//   font-style: normal; */
// }

// @font-face {
//   /* font-family: 'Gramatika';
//   src: url('../public/fonts/Gramatika-Italic.woff2') format('woff2'),
//     url('../public/fonts/Gramatika-Italic.woff') format('woff');
//   font-weight: 400;
//   font-style: italic; */
// }

// @font-face {
//   /* font-family: 'Gramatika';
//   src: url('../public/fonts/Gramatika-Medium.ttf');
//   font-weight: 500;
//   font-style: normal; */
// }

// @font-face {
//   font-family: "Dala Moa";
//   src: url("../public/fonts/Dala\ Moa\ Medium.otf");
//   font-weight: 400;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Dala Moa";
//   src: url("../public/fonts/Dala\ Moa\ Bold.otf");
//   font-weight: 600;
//   font-style: normal;
// }

body {
  margin: 0;
  font-family: "Gramatika", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.eco-banner-title {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.eco-banner-subtitle {
  font-size: 2.5rem;
  color: $white;
  font-weight: 500;
}

.shadow-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 81%;
  background-color: rgba(0, 0, 0, 0.1); /* Color de fondo con transparencia */
}

.text-responsive {
  /* position: "absolute", bottom: "0", left: "0", padding: "20px", color: "white", maxWidth: "30%", marginLeft: "20px", marginBottom: "20px" } */
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 50px;
  padding: 30px;
  color: white;
  max-width: 30%;
  margin-left: 80px;
  margin-bottom: 80px;
}

.sustainability-report-2023-desc {
  font-size: 32px;
}

.bottom-caroussel-title > .sustainability-report-title-2023 {
  display: none;
}

@media (max-width: 768px) {
  .sustainability-report-2023-desc {
    font-size: 1.25rem;
  }

  .text-responsive {
    position: absolute !important;
    bottom: 0;
    left: 0;
    margin: 50px !important;
    margin-bottom: 100px !important;
    padding: 30px !important;
    color: white;
    max-width: 100% !important;
  }

  .text-responsive-wk {
    margin-bottom: 44%;
  }

  .text-responsive-our {
    margin-bottom: 24%;
  }

  .our-img-bg {
    background-image: url("../imgs/firstVideo/mobile.png");
  }

  .wk-img-bg {
    background-image: url("../imgs/secondVideo/rectangle.jpg");
    background-size: cover;
    background-position: center;
  }
}

.text-responsive-wk {
  position: absolute;
  bottom: 0;
  margin-bottom: 8%;
  color: white;
  max-width: 100%;
  width: 100%;
}

.process_certifications img {
  margin-bottom: -50px;
}

.text-responsive-our {
  position: absolute;
  bottom: 0;
  margin-bottom: 2%;
  color: white;
  max-width: 100%;
  width: 100%;
}

.our-img-bg {
  height: 750px;
  width: 100%;
  object-fit: cover;
  background-image: url("../imgs/firstVideo/rectangle.jpg");
  filter: brightness(0.5);
  background-size: cover;
}

.wk-img-bg {
  height: 750px;
  width: 100%;
  object-fit: cover;
  background-image: url("../imgs/secondVideo/rectangle.jpg");
  filter: brightness(0.5);
  background-size: cover;
}

.wk-title {
  font-size: 30px;
  font-weight: 600;
}

.wk-content {
  font-size: 18px;
  font-weight: 400;
  word-wrap: break-word;
}

.play-btn {
  padding: 40px;
  border-radius: 50%;
  border: 2px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.play-btn img {
  width: 100px;
  height: 100px;
  padding-left: 20px;
}

@media (max-width: 768px) {
  .play-btn img {
    width: 50px;
    height: 50px;
    padding-left: 10px;
  }

  .play-btn {
    padding: 20px !important;
  }
}

.play-btn-container {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 768px) {
  .play-btn-container {
    position: absolute !important;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.bottom-caroussel-text {
  position: absolute;
  text-align: center;
  color: white;
  flex-direction: column;
  display: flex;
  gap: 20px;
  padding: 0 10px;
  justify-content: center;
  align-items: center;
}

.bottom-caroussel-title {
  font-size: $h4-font-size;
  font-weight: $font-weight-bold;
}

.bottom-caroussel-desc {
  text-align: center;
  font-size: 22px;
  width: 65% !important;
  margin: 40px 0 !important;
}

@media (max-width: 768px) {
  .bottom-caroussel-desc {
    text-align: left !important;
    padding: 0 20px !important;
    font-size: 16px !important;
    margin: 0 !important;
    width: 100% !important;
  }
}

.bottom-caroussel-bckg {
  width: 100% !important;
  min-height: 700px;
  height: 100%;
  max-height: 80vh;
  filter: brightness(0.5);
}

@media (max-width: 768px) {
  .bottom-caroussel-bckg {
    width: auto !important;
  }
}

/* EmbracingSection */

/* Tabs */

.tabs-container {
  /* background-color: #E0E0E0; */
  background-color: rgba(224, 224, 224, 0.3);
}

.tabs-title {
  color: #00866f;
  font-size: 30px;
  font-weight: 600;
}

.tab-subtitle {
  color: #00866f;
  font-size: 24px;
  font-weight: 600;
}

.tab-option {
  font-size: 18px;
  text-align: center;
  padding: 0 16px;
  margin: 0 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 72px;
  border: 2px solid transparent;
  cursor: pointer;
  font-weight: 500;
  line-height: 1.3rem;
}

.active-tab {
  border: 2px solid #000000;
  border-radius: 12px;
}

.tab-card-title {
  font-size: 24px;
  font-weight: 600;
}

.tab-card-subtitle {
  font-size: 18px;
  font-weight: 400;
  font-style: italic;
}

.tab-card-content {
  font-size: 18px;
  font-weight: 400;
}

.tab-text-center {
  text-align: center;
}

.tab-spacing {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

@media (max-width: 768px) {
  .tab-option {
    font-size: 16px !important;
    padding: 0 8px;
    margin: 0 8px;
    min-height: 56px;
    min-width: 120px;
  }

  .tab-text-center {
    text-align: left;
  }

  .tab-spacing {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .text-start {
    font-size: 14px;
  }
}

/* Modals */

.modal-header-title {
  padding-left: 1rem;
  text-align: left;
  text-wrap: pretty;
}

.modal-title {
  font-family: $font-family-base;
  font-size: 24px;
  font-weight: 600;
  color: #018d90;
}

.modal-text {
  font-size: 16px;
  font-weight: 400;
  text-align: left;
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes fadeOutToRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes fadeOutToBottom {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}

.modal-right .modal-dialog {
  animation: slideInFromRight 0.3s forwards;
}

.modal-right.modal-exit .modal-dialog {
  animation: fadeOutToRight 0.3s forwards;
}

@media (max-width: 768px) {
  .modal-right .modal-dialog {
    animation: slideInFromBottom 0.3s forwards;
  }

  .modal-right.modal-exit .modal-dialog {
    animation: fadeOutToBottom 0.3s forwards;
  }
}

/* evolving */

.evolving-title {
  font-size: 24px;
  font-weight: 600;
}

.evolving-content {
  font-size: 18px;
  font-weight: 400;
}

.evolving-carousel {
  .carousel-item {
    text-decoration: none;
  }
}

@media (max-width: 768px) {
  .evolving-title {
    font-size: 18px;
  }

  .evolving-content {
    font-size: 16px;
  }
}

/* long run */

.long-run-title {
  font-family: "Dala Moa", sans-serif;
  font-size: 30px;
  font-weight: 700;
}

.long-run-content {
  font-size: 18px;
  font-weight: 400;
  padding: 0 20px;
}

.long-run-sub {
  color: #5f7052;
  font-size: 18px;
  font-weight: 500;
  padding: 0 20px;
}

/* caroussel */

.custom-next-icon,
.custom-prev-icon {
  filter: brightness(0) saturate(100%) grayscale(100%) contrast(1)
    brightness(2.2) !important;
}

/* upper sliders  */

// .upper-slider-container {
//   height: 550px;
// }

.slide-height {
  height: 500px;
}

.slider1-title1 {
  font-size: 30px;
  font-weight: 400;
  color: #018d90;
}

.slider1-title2 {
  font-size: 30px;
  font-weight: 600;
  color: #018d90;
}

.slider-1-bg-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.slider12-bg-svg {
  position: absolute;
  bottom: 8vh !important;
  left: 28vh;
  /* height: 100%; */
  height: 22vh;
  width: 91vh;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("../imgs/upperSlider/12.svg");
  z-index: -1;
}

.slider-2-bg-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 60%;
  height: 100%;
}

.slider-3-bg-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 60%;
  height: 100%;
}

.slider1-year {
  font-size: 70px;
  font-weight: 600;
  line-height: 1;
}

.slider2-qty {
  font-size: 70px;
  font-weight: 600;
  line-height: 1;
}

.slider1-country {
  font-size: 18px;
  font-weight: 400;
}

.slider1-line {
  font-size: 50px;
  font-weight: 600;
}

// .slider1-content {
//   padding-top: 20vh;
// }

.slider2-content {
  padding-top: 16vh;
}

.slider1-desc {
  font-size: 18px;
  font-weight: 400;
}

.slider2-desc {
  font-size: 18px;
  font-weight: 400;
}

.first-letter {
  font-size: 70px;
  font-weight: 600;
  line-height: 0.76;
  float: left;
}

.first-letter-slide-2 {
  font-size: 70px;
  font-weight: 600;
  line-height: 1;
  float: left;
}

.slider3-desc {
  font-size: 18px;
  font-weight: 400;
}

.slider4-percent {
  margin-top: 2vh;
  padding-right: 7vh;
}

@media (max-width: 767px) {
  .slider1-title1 {
    font-size: 18px;
  }

  .slider1-title2 {
    font-size: 18px;
  }

  .slider1-year {
    font-size: 50px;
  }

  .slider1-country {
    font-size: 16px;
  }

  .slider1-line {
    font-size: 30px;
  }

  // .slider1-content {
  //   padding-top: 4vh;
  // }

  .slider1-desc {
    font-size: 16px;
    padding: 0 60px;
  }

  .slider-1-bg-container {
    top: 0vh;
    /* overflow-x: hidden; */
  }

  // .slider1-bg-svg {
  //   top: -3vh;
  //   left: -24vh;
  // }

  .slider12-bg-svg {
    top: 34vh;
    left: 0vh;
    width: 50vh;
    height: 20vh;
    background-image: url("../imgs/upperSlider/12mob.svg");
  }

  // .upper-slider-container {
  //   height: 365px;
  //   padding-top: 10vh;
  //   /* margin-bottom: 5vh; */
  // }

  .slide-height {
    height: 600px;
  }

  .slider-2-bg-container {
    width: 100%;
    height: 100%;
    /* top: -16vh */
  }

  .slider-3-bg-container {
    width: 100%;
    height: 100%;
    top: -16vh;
  }

  // .slider2-bg-svg {
  //   top: -4vh;
  //   left: -26vh !important;
  //   height: 28vh;
  //   width: 100vh;
  //   background-image: url("../imgs/upperSlider/2mob.svg");
  // }

  .slider2-content {
    padding-top: 0vh;
  }

  .slider2-desc {
    font-size: 14px;
    padding: 0 0;
  }

  .slider2-qty {
    font-size: 40px;
    font-weight: 600;
    margin-left: 2vh;
  }

  .slider3-desc {
    font-size: 14px;
    padding: 0 0;
    margin-bottom: 0vh;
    z-index: 999 !important;
  }

  .slider1-image-container {
    margin-top: 6vh !important;
  }
  .slider2-image-container {
    margin-top: 4vh !important;
  }

  .slider4-percent {
    margin-top: 0vh;
    padding-right: 0vh;
  }

  .first-letter {
    font-size: 40px;
    line-height: 1;
  }
  .first-letter-slide-2 {
    float: none;
    font-size: 40px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  // .upper-slider-container {
  //   height: 614px;
  // }

  .slider-1-bg-container {
    width: 80%;
  }

  .slider1-bg-svg {
    width: 60%;
  }

  .slider-2-bg-container {
    height: 80%;
  }

  .slider2-bg-svg {
    bottom: 23%;
  }

  .slider3-bg-svg {
    bottom: 35%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .slider-3-bg-container {
    width: 100%;
    top: -16vh;
  }
  
  .slider-2-bg-container {
    top: 10vh;
    width: 100%;
  }
}

/* 1990px styles */
@media (min-width: 1800px) and (max-width: 2559px) {
  .slider2-bg-svg {
    top: -14vh;
  }
}

/* 4k styles */
@media (min-width: 2560px) {
  .slider1-title1 {
    font-size: 50px;
  }

  .slider1-title2 {
    font-size: 50px;
  }

  .slider1-year {
    font-size: 100px;
  }

  .slider1-country {
    font-size: 24px;
  }

  .slider1-line {
    font-size: 70px;
  }

  // .slider1-content {
  //   padding-top: 8vh;
  // }

  .slider1-desc {
    font-size: 24px;
  }

  .slider-1-bg-container {
    top: 0vh;
  }

  .slider12-bg-svg {
    top: 50vh;
    left: 20vh;
  }

  // .upper-slider-container {
  //   height: 750px;
  //   padding-top: 8vh;
  //   margin-bottom: 5vh;
  // }

  .slide-height {
    height: 600px;
  }

  .slider-2-bg-container {
    width: 100%;
    height: 100%;
    top: -10vh;
  }

  .slider-3-bg-container {
    width: 100%;
    height: 100%;
    top: -13vh;
  }
  
  .slider2-content {
    padding-top: 0vh;
  }

  .slider2-desc {
    font-size: 24px;
    padding: 0 0;
  }

  .slider3-desc {
    font-size: 24px;
    padding: 0 0;
    margin-bottom: -1vh;
    z-index: 999 !important;
  }

  .slider1-image-container {
    margin-top: -8vh !important;
  }
}