@include media-breakpoint-up(lg) {
  .header-products-dropdown {
    position: absolute;
    left: 0; // see src/components/Header/styles/_lg-up.scss:6 to understand how it's positioned relative to the .row
    background-color: white;
    box-shadow: 0 4px 7px rgba(0, 0, 0, 0.14);
    padding: 1rem;

    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 7px;
      position: absolute;
      left: 0;
      top: 0;
      @include gradient-y(black, transparent);
      opacity: .15;
    }
  }

  .header-products-dropdown--open {
    margin-top: $menu-underline-height;
  }

  .header-products-dropdown__top {
    padding-bottom: 1.5rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid #EAEAEA;
  }

  .products-dropdown-markets {
    display: flex;
  }

  .products-dropdown-link {
    span {
      display: block;
    }

    &:hover {
      text-decoration: none;
    }
  }

  .products-dropdown-link {
    &:hover {
      .products-dropdown-link__subtitle {
        text-decoration: underline;
      }
    }
  }

  .products-dropdown-link__title {
    font-weight: $font-weight-medium;
    margin-bottom: .3rem;
    color: $very-dark-cyan;
    text-transform: uppercase;
  }

  .products-dropdown-link__title--marine {
    color: $prussian-blue;
  }

  .products-dropdown-link__subtitle {
    color: $gray-light;
    font-weight: $font-weight-light;
    font-size: $font-size-sm;
  }
}
