@media (min-width: 100px) and (max-width: 400px) {
  .characteristic-details--modal-mobile > .modal-dialog > .modal-content {
    border-radius: 20px !important;
    width: 100% !important;
    margin: 0 0 !important;
  }

  .characteristic-details--modal-mobile > .modal-dialog {
    width: 100% !important;
  }
}