.cursor-pointer {
  cursor: pointer;
}

.order-detail-container {
  height: 400px;
}

.text-divider-title-banner::after {
  content: "";
  margin-left: 170px;
  border: 1.5px solid black;
}