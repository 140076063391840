.timeline-item {
  position: relative;
  padding: 2rem 0;

  &:after {
    content: "";
    display: block;
    width: 1px;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 0;
    background-color: lighten(#979797, 10%);
  }

  > .row {
    > [class*="col"] {
      &:first-child {
        &:after {
          content: "";
          display: block;
          width: 12px;
          height: 16px;
          position: absolute;
          top: 0;
          background: url("../img/triangle.svg") no-repeat center center white;
          z-index: 10;
        }
      }
    }
  }
}

.timeline-year {
  font-family: $headings-font-family;
  font-size: $h2-font-size;
  line-height: $h2-font-size * 0.5;
}

.timeline-title {
  font-weight: $font-weight-bold;
}

.timeline-content {
  font-size: $font-size-sm;
  color: $gray;
}

.timeline-item--odd {
  > .row {
    > [class*="col"] {
      &:first-child {
        text-align: right;
        position: relative;

        &:after {
          right: -1px;
        }
      }
    }
  }

  .timeline-year {
    padding-right: 12px;
  }
}

.timeline-item--even {
  > .row {
    > [class*="col"] {
      &:first-child {
        order: 2;

        &:after {
          left: 0;
          transform: rotate(180deg);
        }
      }

      &:last-child {
        text-align: right;
      }
    }
  }

  .timeline-year {
    padding-left: 12px;
  }
}
