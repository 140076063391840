@include media-breakpoint-up(xl) {
  .image-hotspot--container {
    height: 640px;
    overflow: visible !important;
  }

  .image-hotspot--container > div {
    overflow: visible !important;
  }
  
  .image-hotspot--container > div > div > div {
    background-color: transparent !important;
    box-shadow: none !important;
  }

  .image-hotspot--container > .hotspot-point {
    z-index: 100;
  }

  .hotspot-point-text {
    font-family: $font-family-sans-serif;
    font-size: 1rem;
    font-weight: 400;
    width: max-content;
    display: block;
    background-color: white;
    border: 0.5px solid #6C757D;
    text-align: left;
    position: relative;
    top: -29px;
    padding-left: 20px;
    padding-right: 20px;
    padding-block: 0px;
    z-index: 100;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .hotspot-point {
    background-color: #052e60;
    position: relative;
    color: white;
    right: 18px;
    width: 30px;
    height: 30px;
    font-size: 18px;
    font-family: $font-family-sans-serif;
    font-size: 1rem;
    cursor: pointer;
  }

  .hotspot-point-overlap {
    z-index: 1001;
  }

  .hotspot-point-text-overlap {
    z-index: 1000;
  }
}
