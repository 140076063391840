.spglobal-header {
  background-color: $header-background-color;
}

.spglobal-header--marine {
  background-color: $prussian-blue;
}

.spglobal-header--resources {
  background-color: $gray;
}

.spglobal-header__cart {
  background-color: $gray-800;
  max-height: $header-cart-height;
}

.spglobal-header__cart--button {
  color: white;
  text-decoration: none;
  font-weight: $font-weight-bold;
  display: block;
  margin: 0;
  padding: 10px 0 8px;

  &:hover {
    color: white;
    text-decoration: none;
  }

  i {
    position: relative;

    &:first-child {
      &:after {
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        position: absolute;
        right: -5px;
        top: -3px;
        background-color: $very-dark-cyan;
        border-radius: 100%;
      }
    }
  }
}

.header-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: $header-height;
  // width: 200px;
  margin: auto;
}
