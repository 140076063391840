
.modal-title {
    text-align: left !important;
}

.spacing-inputs {
    flex-basis: 30%;
}

.placeholder {
    color: #b0b0b0; // Color gris claro
    font-style: italic;
}