.filters-bar-search-form {
  .form-control {
    border-right: none;
  }

  .input-group-text {
    background-color: transparent;
  }
}

.filters-bar {

  .filters-bar__remove-all {

    i {
      font-size: 20px;
      margin-left: 5px;
    }
  }
}

.filters-bar-title {
  display: block;
}