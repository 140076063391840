.main-container-products-home-quickship-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f7f6fb76;
}

.home--products-container {
  height: fit-content;

  max-height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scrollbar-width: none; 
  scroll-behavior: smooth;
  
  ::-webkit-scrollbar {
    width: 0px;  
  }
}

.home--products-container-product {
  height: 100vh;
  scroll-snap-align: center;


  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: cover;

  width: 50%;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  section {
    position: absolute;

    h1 {
      color: #FFF;
      text-align: center;
      font-family: "Gramatika";
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 40px;
    }

    .btn--Products-home-quickship {
      color: #FFF;
      text-align: center;
      font-family: "Gramatika";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      width: 210px;
      height: 52px;
      flex-shrink: 0;

      background-color: #000000;
      padding: 1.25rem;
      border-radius: 1.25rem;
      text-decoration: none;
      transition: all 0.2s ease-in-out;

      &:hover {
        background-color: #ffffff;
        color: #000000;
      }
    }
  }


  .home--products-container-arrow-down {
    display: none;
  }


  @media (max-width: 768px) {
    width: 100% !important;
    max-height: 100vh;
    align-items: center;
    justify-content: flex-end;

    section{
      padding-bottom: 12rem;
    }

    .home--products-container-arrow-down {
      position: absolute;
      bottom: 6rem;
      display: flex;

      img {
        width: 40px;
        height: 40px;
      }
    }

    &:last-child .home--products-container-arrow-down {
      img {
        display: none;
      }
    }
  }
}

