.faq-item {
  border-top: 1px solid #CFCFCF;
  padding: 1rem 0;
  position: relative;

  &:last-child {
    border-bottom: 1px solid #CFCFCF;
  }
}

.faq-item__toggle-button {
  position: absolute;
  right: 0;
  top: 1rem;
  width: $faq-item-button-radius;
  height: $faq-item-button-radius;
  background-color: transparent;
  padding: 0;
  border: 2px solid black;
  border-radius: 100%;
  font-size: $faq-item-button-radius * 0.5;
  box-sizing: content-box;

  i {
    display: block;
    width: $faq-item-button-radius;
    height: $faq-item-button-radius;
    line-height: $faq-item-button-radius;
    text-align: center;
  }
}

.faq-item__question {
  font-size: $h5-font-size;
  margin-right: $faq-item-button-radius;
  margin-bottom: 0;
  padding-right: .5rem;
  min-height: $faq-item-button-radius + 4px;  // + 4px border width
}

.faq-item__answer {
  color: $gray-light;
  font-weight: $font-weight-light;
  font-size: $font-size-sm;
  margin-bottom: 0;
  display: none;
  padding-top: 1rem;
}

.faq-item--open {
  .faq-item__answer {
    display: block;
  }
}
