.filters-box {
  border: 1px solid $filters-box-border-color;
  margin-bottom: $grid-gutter-width-half;
}

.filters-box__header {
  h3 {
    height: $filters-box-header-height;
    line-height: $filters-box-header-height;
    margin: 0;
    padding: 0 $grid-gutter-width-half;
    color: $gray-light;
    font-size: $h6-font-size;

    i {
      float: right;
      height: $filters-box-header-height;
      line-height: $filters-box-header-height;
      font-size: $font-size-base;
    }
  }
}

.filters-box__body {
  max-height: 0;
  overflow: hidden;
  @include transition($transition-base);
}

.filters-box__body--visible {
  max-height: 300px;
  overflow-y: auto;
}

.filters-box-content {
  padding: $grid-gutter-width-half;
  border-top: 1px solid $filters-box-border-color;
  background-color: $filters-box-content-background;
}