.search-results-page {

  .search-result__result {
    border-top: 1px solid gray;
    padding: 20px 0;

    .search-result__type {
      color: $gray-light
    }

    .search-result__title {
      font-weight: 700;
    }
  }
}