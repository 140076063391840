.proseat-characteristic--content { 
  margin-bottom: 0.725rem;
  border-radius: 20px;
  font-size: 1rem;
  color: white;
  border: 1.2px solid #052e60;
  font-weight: 400;
  display: flex;
  flex: 0 0 100%;
  width: 98%;
  max-width: 100%;
  height: 43px;
  box-sizing: border-box;
  background-color: #052e60;
  transition: background-color 400ms ease-out, border-color 400ms ease-out;
}

.proseat-characteristic--number, .proseat-characteristic--description{
  font-family: $font-family-sans-serif;
}

.proseat-characteristic--number {
  position: relative;
  background-color: #0071AC;
  color: white;
  right: 20px;
  width: 33px;
  height: 33px;
  font-size: 0.875rem;
  font-weight: 400;
}