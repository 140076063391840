@include media-breakpoint-up(md) {
  .product-header__top-section {
    height: 40vh;
    min-height: 20rem;

    > .container {
      height: 100%;

      > .row {
        height: 100%;
      }
    }
  }

  .product-header-name {
    padding: 1rem;
  }
}