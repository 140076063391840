@include media-breakpoint-up(lg) {
  .container-certifications {
    height: 300px;
  }

  .certificate-container-img {
    height: 100px;
    overflow: hidden;
  }

  .best-workplaces-certificate {
    width: 75px;
    height: 75px;
    margin-inline: auto;

  }

  .best-workplaces-colombia-certificate {
    width: 90px;
    height: 90px;
    margin-inline: auto;
  }

  .carbono-neutral-certificate {
    width: 220px;
    height: 220px;
    margin-inline: auto;
  }

  .carbono-neutral-certificate-en {
    width: 160px;
    height: 160px;
  }

  .iso-45001-certificate,
  .iso-50001-certificate,
  .iso-14001-certificate  {
    width: 140px;
    height: 140px;
  } 

  .med-certificate {
    width: 120px;
    height: 100px;
    margin-inline: auto;
  }

  .oea-certificate {
    width: 140px;
    height: 140px;
    margin-inline: auto;

  }

  .uca-certificate {
    width: 120px;
    height: 120px;
    margin-inline: auto;

  }

  .basc-certificate {
    width: 80px;
    height: 80px;
    margin-inline: auto;
  }

  .iso-45001-certificate, .iso-14001-certificate {
    margin-inline: auto;
  }

  .iso-1649-sgs-certificate,
  .iso-90001-sgs-certificate {
    width: 80px;
    height: 80px;
    margin-inline: auto;
  }
}