@include media-breakpoint-up(sm) {
  .product-line {
    height: 100%;
    padding-bottom: 4rem;
    position: relative;
  }

  .product-line__description {
    //min-height: 11rem;
  }

  .product-line__button-container {
    position: absolute;
    bottom: 1.5rem;
    width: 100%;
  }
}