@include media-breakpoint-up(md) {
  .testimonial--slide-container {
    max-height: 400px;
    height: 350px;
  }

  .testimonial-modal--big-img {
    aspect-ratio: 9/12;
  }

  .testimonial-modal--container-img {
    max-height: 500px;
  }
}