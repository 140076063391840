@include media-breakpoint-down(sm) {
  .slick-dots {
    position: initial;
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
  }

  .container-info--sm-rv {
    padding: 0 1rem;
  }

  .container-title--sm-rv {
    padding: 0 1rem;
  }

  .large-img--sm-rv {
    height: 304px;
    padding: 0 1rem;
  }

  .large-img--masiala {
    border-radius: 1rem;
    // height: 254px !important;
    max-width: 100%;
  }
}
