.gm-style .gm-style-iw-c {
  padding: 0 !important;

  > .gm-style-iw-d {
    overflow: auto !important; // removes white space at right and bottom

    > div {
      width: 100%;
    }
  }

  > button {
    right: 0 !important;
    top: 0 !important;
  }
}
