.header-products-dropdown {
  display: none;
}

.header-products-dropdown--open {
  display: block;
}

.header-products-dropdown--disabled {
  color: $gray-light;

  &:hover {
    color: $gray-light;
  }
}

.products-dropdown-link {
  display: block;
}