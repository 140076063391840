.new-label {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: $white;
  font-size: $font-size-sm;
  font-weight: $font-weight-medium;
  background: $very-dark-cyan;
  height: 1.5rem;
  width: 4rem;

  &::before {
    content: "";
    border-top: 0.875rem solid $very-dark-cyan;
    border-left: 2rem solid transparent;
    border-right: 2rem solid transparent;
    height: 0;
    left: 0;
    width: 0;
    position: absolute;
    bottom: -0.875rem;
  }
}

.new-label-absolute {
  position: absolute;
  top: 0;
  right: 0;
}

.new-label--text {
  position: absolute;
  top: 20%;
}
