.header-market-segment__title {
  text-transform: uppercase;
}

.header-market-segment__links {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.header-market-segment__chevron {
  display: none;
}