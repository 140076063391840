@include media-breakpoint-up(xl) { 
  .sustainable--slider-next-arrow, .sustainable--slider-prev-arrow {
    position: relative;
    top: 50%;
    filter: brightness(0) invert(1);
  }

  .sustainable-reports--slider > .carousel-control-prev {
    background-image: none !important;
  }

  .sustainable-reports--slider > .carousel-control-next {
    background-image: none !important;
  }

  .carousel-desc-sustainability-reports > .sustainability-report-2023-desc {
    font-size: 22px;
  }
 }