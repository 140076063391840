.requested-sample-card {
  border: 1px solid $gray-200;
  padding: 1rem;
}

.requested-sample-card--pattern {
  img {
    max-height: 90px;
  }
}

.requested-sample-card--product {
  img {
    width: 90px;
    height: auto;
    border: 1px solid white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  }
}

.requested-sample-card--deleting {
  opacity: .8;
}