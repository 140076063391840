.our-essence--poster-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.our-essence--poster-icon-play {
  position: absolute;
  top: 45%;
  left: 45%;
  cursor: pointer;
  width: 40px;
  height: 40px;
}

.our-essence--video-container {
  height: auto;
}

.btn_work_with_us {
  background-color: #000000;

  &:hover {
    filter: brightness(1.2);
  }
}