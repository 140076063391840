@include media-breakpoint-down(lg) {
  .play-btn img {
    width: 50px;
    height: 50px;
    padding-left: 10px;
  }

  .play-btn {
    padding: 20px !important;
  }

  .play-btn-container {
    position: absolute !important;
    top: 32% !important;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
