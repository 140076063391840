.privacy-policy-checkbox {
  a {
    color: inherit;
    font-weight: $font-weight-bold;
  }
}

%field-check {
  top: 2px;
  width: 15px;
  height: 15px;
  appearance: none;
  position: relative;
  border: solid 1px $gray-700;
  cursor: pointer;
}

%field-check-checked {
  content: "✓";
  position: absolute;
  width: 16px;
  height: 16px;
  top: -0.900px;
  left: -1.063px;
  text-align: center;
  font-size: 13px;
  color: $white;
  background: $black;
  border: solid 1px $gray-700;
}

.step-form--field-check {
  @extend %field-check;
  &:checked::before {
    @extend %field-check-checked;
  }
}

.step-form--field-check--controlled {
  @extend %field-check;
}

.step-form--field-check--controlled-checked {
  &::before {
    @extend %field-check-checked;
  }
}

.underlined-private-policy {
  text-decoration: underline;
  cursor: pointer;
}