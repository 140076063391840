.characteristic-detail--container {
  border-radius: 20px;
  height: fit-content;
}

.characteristic-detail--title {
  font-weight: 700;
  font-family: $font-family-sans-serif;
  font-size: 1.125rem;
}

.characteristic-detail--description {
  font-size: 1rem;
  font-family: $font-family-sans-serif;
  line-height: 20px;
  font-weight: 300;
}

.characteristic-detail--button {
  font-family: $font-family-sans-serif;
  font-size: 1rem;
  background-color: #052e60 !important;
  border-color: #052e60 !important;
}

.button-middle-width {
  width: 48%;
}