@include media-breakpoint-up(md) {
  .testimonial--slide-container {
    max-height: 400px;
    height: fit-content;
  }

  .hover-w-50:hover {
    width: 150% !important;
    height: 100%;
  }
}