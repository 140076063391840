.css-1hb7zxy-IndicatorsContainer {
  margin-right: 2px;
}

.css-1dzwskc-singleValue {
  padding: 0 !important;
}

svg {
  width: 14px !important;
}