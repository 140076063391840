.header-circle-button {
  border: none;
  height: 100%;
  padding: 0;
  outline: none;
  background: none;

  i, img {
    display: block;
    width: $circle-btn-radius;
    height: $circle-btn-radius;
    line-height: $circle-btn-radius;
    border-radius: 50%;
  }

  i {
    background-color: $header-background-color;
    color: white;
    @include transition(ease-out 100ms all);
  }

  &:hover,
  &:focus,
  &:active,
  &.header-circle-button--active {
    outline: none;

    i {
      color: $header-background-color;
      background-color: $dark-cyan;
      outline: none;
    }
  }

  &:first-child {
    margin-right: 2px;
  }
}

.header-circle-button--marine {
  i {
    background-color: $prussian-blue;
    color: white;
    @include transition(ease-out 100ms all);
  }

  &:hover,
  &:focus,
  &:active,
  &.header-circle-button--active {
    outline: none;

    i {
      color: $prussian-blue;
      background-color: $white;
      outline: none;
    }
  }
}

.header-circle-button--active {
  position: relative;

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 3px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: $very-dark-cyan;
  }
}

.header-circle-button--marine--active {

  &:after {
    background-color: $white;
  }
}
