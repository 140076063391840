.proseat-icon--container {
  display: flex;
  justify-content: end;
  padding: 0;
  position: inherit;
  z-index: 1040;
}

.icon-search {
  box-sizing: border-box;
  background-color: #0071AC;
  border-radius: 20px;
  width: 124px;
  height: 38px;
}

// .icon-search:hover {
//   width: 170px;
//   animation-name: overlay;
//   animation-duration: 1s;
// }

.proseat-glossary--text {
  font-family: $font-family-sans-serif;
  font-size: 1rem;
}

.speech-bubble {
  position: relative;
  background: #052e60;
  height: fit-content;
  border-radius: .4em;
  padding: 10px 15px;
  bottom: 100%;
  z-index: 1000;
  color: white;
  transform: translateX(5%) translateY(-110%);
  -webkit-animation: fadeinout 5s linear forwards;
  animation: fadeinout 5s linear forwards;
}

.speech-bubble:after {
  content: '';
  display: inline-block;
  position: relative;
  width: 0;
  left: -91.3%;
  border: 10px solid transparent;
  border-right-color: #052e60;
  border-left: 0;
  margin-top: -40px;
  margin-left: -31px;
  z-index: 1020;
  bottom: -6px;
}