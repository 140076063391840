@include media-breakpoint-up(lg) {
  .header-menu-button {
    background-color: transparent;
    border: none;
    text-align: left;
    margin: 0;

    display: block;
    height: $header-height;
    line-height: $header-height;
    color: $white;
    padding: 0 5px;

    &:hover,
    &:focus,
    &:active {
      outline: none;
      text-decoration: none;
      box-shadow: none;
      color: $white;
    }
  }

  .header-menu-button--active:after {
    background: $very-dark-cyan;
    content: "";
    display: block;
    height: $menu-underline-height;
    transform: scaleX(1);
  }

  .header-menu-button--active--marine:after {
    background: $white;
  }
}