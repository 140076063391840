.no-match {
  display: flex;
  flex-flow: column;
  margin: 0;
  min-height: 92vh;
}

.no-match-main {
  flex-grow: 1;
  text-align: center;
}

.no-match-main-container {
  position: relative;
  margin-top: 3.5rem;
}

.label-container {
  position: absolute;
  top: 25%;
  right: 0;
  left: 0;
}

.ban-icon {
  font-size: 30rem !important;
  color: #f2f1f1;
}

.label {
  font-size: 8rem;
}
