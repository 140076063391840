.blog-post-card {
  height: 100%;

  .blog-post-title__heading {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .blog-post-title__description-text {
    font-size: 14px;
    font-weight: $font-weight-light;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: $description-text-line-height;     /* fallback */
    max-height: $description-text-line-height * $description-text-lines;      /* fallback */
    -webkit-line-clamp: $description-text-lines; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
}