// Overlay Animation
@keyframes overlay{
  from {
    opacity: 0.5;
  }

  to {
    opacity: 1;
  }
}

// Shake Animation
@keyframes shake {
  0% {
    transform: translateX(0) translateY(-100%);
  }
  25% {
    transform: translateX(-5px) translateY(-100%);
  }
  50% {
    transform: translateX(5px) translateY(-100%);
  }
  75% {
    transform: translateX(-5px) translateY(-100%);
  }
  100% {
    transform: translateX(0) translateY(-100%);
    animation-play-state: paused;
  }
}

// Fade In Animation
@keyframes fadeinout {
  0%,100% { opacity: 0; }
  50% { opacity: 1; }
}

@keyframes fadeinout {
  0%,100% { opacity: 0; }
  50% { opacity: 1; }
}