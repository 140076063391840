@include media-breakpoint-up(xl) {
  .proseat-characteristic--content {
    margin-bottom: 0.725rem;
    border-radius: 20px;
    font-size: 1rem;
    color: #052e60;
    border: 1.2px solid #0071AC;
    font-weight: 400;
    width: 435px;
    display: flex;
    flex: 0 1 100%;
    max-width: 435px;
    height: 43px;
    box-sizing: border-box;
    background-color: transparent;
    transition: background-color 400ms ease-out, border-color 400ms ease-out;
  }

  .proseat-characteristic--content:hover {
    background-color: #052e60;
    border-color: #052e60;
    cursor: pointer;
    color: white;
    transition: background-color 400ms ease-out, border-color 400ms ease-out;
  }

  .proseat-characteristic--number {
    right: 18px;
    font-size: 1rem;
  }
}