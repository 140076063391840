.header-subinstances {
  display: flex;
  align-items: center;
  background-color: $gray-800;

  a {
    color: white;
    text-decoration: none;
    display: block;
  }
}

.header-subinstances-menu-button--active {
  border: 1px solid !important;
  border-radius: 0.5rem !important;
}

.header-subinstances-menu-button {
  font-size: $font-size-sm;
}

.header-subinstances-icon {
  width: 1rem;
  height: 1rem;
}
