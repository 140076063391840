.auth-box-user-card {

}

.auth-box-user-card__profile-pic {
  width: $profile-picture-width;
  float: left;
}

.auth-box-user-card__user-info {
  margin-left: $profile-picture-width;
  padding-left: 1.5rem;
  float: none;
}