.glossary-word {
  font-size: 1rem; 
  font-family: $font-family-sans-serif;
  line-height: normal;
}

.glossary-word-name {
  font-weight: 600;
  font-size: 1rem;
  font-family: $font-family-sans-serif;
  line-height: normal;
}

.glossary-no-words-found {
  font-weight: 400;
  font-size: 1.125rem;
  font-family: $font-family-sans-serif;
}

.glossary-words-container {
  height: 87%;
}