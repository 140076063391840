@include media-breakpoint-up(xl) {
  .proseat-icon--container {
    bottom: 0;
    display: flex;
    justify-content: start;
    position: sticky !important;
  }

  .proseat-icon--container-without-sticky {
    padding: 0;
    display: flex;
    position: inherit;
    justify-content: start;
  }
  
  .icon-search {
    position: relative;
    box-sizing: border-box;
    background-color: #0071AC;
    width:  75px;
    height: 55px;
    border-radius: 20px;
    left: 0%;
    z-index: 1000;
    transform: translateY(-100%);
    transition: all 100ms ease;
  }
  
  .icon-search:hover {
    width: 170px;
    animation-name: overlay;
    animation-duration: 1s;
  }
  
  .proseat-glossary--text {
    font-family: $font-family-sans-serif;
    font-size: 1.375rem;
  }

  .icon-search--animation-shake {
    animation-name: shake;
    animation-duration: 0.3s;
    animation-iteration-count: 6;
    animation-timing-function: linear;
  }
}
