.company-plants--banner-slide {
  position: relative;
  background-color: white;
  background-repeat: no-repeat;
  background-size: cover;
}

.company-plants--banner-slide::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.25);
}

.banner-plants--title {
  font-size: 1.875rem;
}

.banner-plants--description {
  font-size: 1rem;
}

.company-plants--banner-slider {
  height: 512px;
}

.company-plants--banner-slider > .slick-next {
  right: 3%;
}

.company-plants--banner-slider > .slick-list {
  z-index: -1;
}

.company-plants--slider-next-arrow, .company-plants--slider-prev-arrow {
  filter: brightness(0) invert(1);
}

.company-plants--banner-slider > .slick-next::before, .slick-prev::before {
  content: " ";
}

.company-plants--banner-slider > .slick-prev {
  left: 3%
}

.company-plants--banner-slider > .slick-list, .slick-track, .slick-slide {
  height: 100%;
}

.company-plants--banner-slider > .slick-list > .slick-track > .slick-slide > div {
  height: 100%;
}

.banner-plants--section {
  z-index: 5;
}