@include media-breakpoint-up(xl) {
  .glossary-section--container {
    width: 404px;
    height: 618px;
    border-radius: 20px;
    position: fixed;
    bottom: 3%;
    left: 10%;
    transform: translateY(0vh);
    animation-name: show-glossary;
    animation-duration: 300ms;
    transition: all transform 500ms ease-in-out;
    z-index: 1031;
  }

  .glossary-search-input::placeholder {
    font-family: $font-family-sans-serif;
    font-weight: 300;
    line-height: normal;
    font-size: 1rem;
  }

  .glossary-section--container-hide {
    width: 404px;
    height: 618px;
    border-radius: 20px;
    position: fixed;
    left: 10%;
    // top: -630px;
    bottom: 3%;
    transform: translateY(100vh);
    animation-name: hide-glossary;
    animation-duration: 300ms;
    transition: all transform 500ms ease-in-out;
    z-index: 1031;
  }

  .glossary-section--header {
    height: 85px;
    background-color: #052e60;
    position: relative;
  }

  .icon-close {
    position: absolute;
    top: 8px;
    right: 10px;
    z-index: 5;
  }

  .glossary-section--header-content {
    border-radius: 20px; 
    height: 40px;
  }

  .glossary-section--header-search {
    border-radius: 20px;
    background-color: #052e60;
    position: relative;
    left: 5px;
  }

  .glossary-section--header-content > input {
    border-radius: 20px; 
  }

  .glossary-section--header-content > input:focus {
    outline: none;
  }

  .glossary-section--footer {
    height: 48px;
    border-radius: 25px;
    line-height: 1.125rem;
    background-color: #0071AC;
    color: white;
    font-weight: 600;
    font-family: $font-family-sans-serif;
    font-size: 1rem;
    position: relative;
  }
}
