.hero-title {
  height: 60vh;
  min-height: 320px;
  position: relative;
  background-color: $skeleton-background;
  background-size: cover;
  background-position: center center;

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.hero-title__content {
  position: absolute;
  width: 100%;
  bottom: 0;
  color: white;
  z-index: 2;

  .container {
    padding-right: $sub-hero-shape-width * 0.5;
    padding-bottom: $sub-hero-shape-height * 0.6;
  }
}
