.container-certifications {
  height: fit-content;
}

.company--plants-certification-img {
  object-fit: contain;
}

.certificate-container-img {
  height: 110px;
  overflow: hidden;
}

.best-workplaces-certificate {
  width: 57px;
  height: 57px;
}

.best-workplaces-colombia-certificate {
  width: 90px;
  height: 90px;
}

.carbono-neutral-certificate {
  width: 169px;
  height: 169px;
}

.carbono-neutral-certificate-en {
  width: 100px;
  height: 100px;
}

.iso-45001-certificate,
.iso-50001-certificate,
.iso-14001-certificate {
  width: 105px;
  height: 105px;
}

.med-certificate {
  width: 75px;
  height: 75px;
}

.oea-certificate {
  width: 105px;
  height: 105px;
}

.uca-certificate {
  width: 90px;
  height: 90px;
}

.basc-certificate,
.iso-4001-certificate,
.iso-1649-sgs-certificate,
.iso-90001-sgs-certificate {
  width: 60px;
  height: 60px;
}