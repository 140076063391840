form.documents-list-filter {
  .input-group {
    input {
      border-right: none;
      border-radius: $input-height;
    }

    .input-group-append {
      border-left: none;

      .input-group-text {
        background-color: transparent;
        border-top-right-radius: $input-height;
        border-bottom-right-radius: $input-height;
      }
    }
  }
}