.spglobal-footer {
  background-color: #282828;

  p,
  h5,
  a,
  form,
  label,
  button {
    font-size: $font-size-sm;
  }

  p {
    color: $gray-light;
  }

  h5,
  a {
    color: white;
  }

  a:hover {
    color: white;
  }

  h5 {
    font-weight: $font-weight-bold;
    text-transform: uppercase;
  }
}

.footer-links {
  a {
    font-weight: $font-weight-medium;
  }

  &:not(.footer-links--social) {
    a {
      display: block;
    }
  }

  &.footer-links--instances {
    a {
      text-transform: uppercase;
    }
  }

  &.footer-links--social {
    a {
      i {
        color: #9b9b9b;
      }
    }
  }
}

.footer-copyright {
  padding: 2rem 0 2.3rem;

  img {
    height: 4rem;
    width: auto;
    opacity: 0.4;
  }

  p {
    font-size: $font-size-xs;
  }
}

.footer-copyright--latam {
  img {
    height: 5rem;
  }
}
