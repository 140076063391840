.product-line {
  text-align: center;
  background-color: $light-grayish-yellow;
  padding-bottom: 1.5rem;
}

.product-line__photo {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 65.4%; // aspect ratio of the image
  background-color: $skeleton-background;

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
  }
}

.product-line__title {
  a {
    color: black;
  }
}

.product-line__description {
  text-overflow: ellipsis;
  overflow: hidden;
  // Addition lines for 2 line or multiline ellipsis
  display: -webkit-box !important;
  -webkit-line-clamp: 12;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.product-line__button-container {
}
