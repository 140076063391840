@include media-breakpoint-up(xl) {
  .header-market-segment__title {
    font-size: $font-size-sm;
    //height: 2rem;
  }

  .header-market-segment__links {
    li {
      a {
        font-size: $font-size-sm;
      }
    }
  }
}