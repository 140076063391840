.glossary-section--container {
  width: 404px;
  height: 618px;
  border-radius: 20px;
  z-index: 1000;
}

.glossary-section--header {
  height: 85px;
  background-color: #052e60;
  position: relative;
}

.icon-close {
  position: absolute;
  top: 8px;
  right: 10px;
  z-index: 5;
}

.glossary-search-input::placeholder {
  font-family: $font-family-sans-serif;
  font-weight: 300;
  line-height: normal;
  font-size: 1rem;
}

.glossary-section--header-content {
  border-radius: 20px; 
  height: 40px;
}

.glossary-section--header-search {
  border-radius: 20px;
  background-color: #052e60;
  position: relative;
  left: 5px;
}

.glossary-section--header-content > input {
  border-radius: 20px; 
}

.glossary-section--header-content > input:focus {
  outline: none;
}

.glossary-section--footer {
  height: 48px;
  border-radius: 25px;
  line-height: 1.125rem;
  background-color: #0071AC;
  color: white;
  font-weight: 600;
  font-family: $font-family-sans-serif;
  font-size: 1rem;
}

.glossary-section--footer-foam, .glossary-section--footer-vinyl {
  border-radius: 24px;
  background-color: transparent;
  z-index: 2;
}

.glossary-element-animation-switch-static {
  position: absolute;
  height: 7.8%;
  width: 50%;
  border-radius: 24px;
  left: 0%;
  background-color: #052e60;
  z-index: 1;
}

.glossary-element-animation-switch-on {
  position: absolute;
  height: 7.8%;
  width: 50%;
  border-radius: 24px;
  left: 50%;
  background-color: #052e60;
  animation: swipe-move-on;
  animation-duration: 300ms;
  transition: all left 500ms ease-in-out;
}

.glossary-element-animation-switch-off {
  position: absolute;
  height: 7.8%;
  width: 50%;
  border-radius: 24px;
  left: 0%;
  background-color: #052e60;
  animation: swipe-move-off;
  animation-duration: 300ms;
  transition: all left 500ms ease-in-out;
}

.glossary-section--modal-mobile > .modal-dialog {
  margin: 0;
}

.glossary-section--modal-mobile > .modal-dialog > .modal-content {
  border-radius: 20px !important;
}

.glossary-background-switch-element {
  height: 48px; 
  position: relative;
  top: -48px;
}

.glossary-spinner {
  color: #0071AC;
}

.glossary-section--footer-related-word {
  background-color: #052e60;
  font-weight: 500;
  cursor: pointer;
  z-index: 3;
}

.glossary-words-container-height {
  height: 85%;
}