@media (min-width: 840px) and (max-width: 983px){
  .bio-section--bio-attr-title {
    padding-inline: 3rem !important;
  }
}

@media (min-width: 990px) and (max-width: 1026px) {
  // .bio-content {
  //   font-size: 23px !important;
  // }

  .giving-new-life-btn {
    padding-inline: 10px !important;
  }

}