@include media-breakpoint-down(sm) {
  .home-featured-project {
    > .container {
      padding: 0;
      width: 100%;
      max-width: none;

      > .row {
        margin: 0;

        > [class*="col"] {
          &:first-child {
            padding: 0;
          }
        }
      }
    }
  }
}