@include media-breakpoint-up(lg) {
  .contact-box {
    padding: 1rem;

    > .row {
      > [class*="col"] {
        &:first-child {
          padding-right: 1.5rem;
        }

        &:last-child {
          position: relative;
          padding: 0 1.5rem;

          &:after {
            content: "";
            display: block;
            height: 100%;
            width: 1px;
            position: absolute;
            left: 0;
            top: 0;
            background-color: rgba(255, 255, 255, 0.2);
          }
        }
      }
    }
  }

  .contact-box-text {
    text-align: right;
    float: right;
    font-size: $h3-font-size;
  }

  .contact-box-form {
    padding: 1rem 0;
  }
}
