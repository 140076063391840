@include media-breakpoint-up(md) {
  .fw-article {

  }

  .fw-article-background {
    position: absolute;
    width: 50vw;
    height: 100%;
    top: 0;
  }

  .fw-article-content {
    padding: 0 0 0 1rem;
    min-height: 30rem;
    display: flex;
    align-items: center;
  }

  .fw-article-content__wrapper {

  }

  .fw-article {
    &:nth-child(odd) {
      .fw-article-background {
        right: 0;
      }
    }

    &:nth-child(even) {
      > .container {
        > .row {
          > [class*="col"] {
            &:first-child {
              order: 1;
            }
          }
        }
      }

      .fw-article-background {
        left: 0;
      }
    }
  }
}
