@use "sass:math";

@include media-breakpoint-up(md) {
  .references-carousel-container {
    position: relative;
  }

  .references-carousel-content {
    margin-left: $grid-small-gutter-width * -.5;
    margin-right: $grid-small-gutter-width * -.5;
  }

  .references-carousel-content__card {
    flex: 0 0 math.div(100%, 6);
    max-width: math.div(100%, 6);
    width: 100%;
    padding-left: $grid-small-gutter-width * .5;
    padding-right: $grid-small-gutter-width * .5;
    position: relative;
  }
}