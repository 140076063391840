.post-details {
  min-height: 50vh;
}

.post-details__share {

}

.post-tags {
  a {
    display: inline-block;
    float: left;
    margin-right: .5rem;
    color: $gray;
  }
}
