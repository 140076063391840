@include media-breakpoint-down(sm) {
  .product-header__top-section {
    padding: 10rem 0 0;
  }

  .product-header__other-references {
    > .container {
      padding: 0;
    }
  }
}