.home-featured-project {
  background-color: $cyan-darker;
}

.home-featured-project--marine {
  background-color: $prussian-blue;
}

.home-featured-project-see-all-button {
  display: inline-block;
}

.home-featured-project-image {
  width: 100%;
  height: 0;
  padding-bottom: 48%;
  background-position: center center;
  background-size: cover;
  background-color: $skeleton-background;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
  }
}

.project-details {
  color: white;
}
