.home-slide {
  position: relative;
  background-color: $skeleton-background;
  background-position: center center;
  background-size: cover;

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.home-slide__content {
  position: relative;
  z-index: 2;
}

.slide-content {
  text-align: left;
  color: white;
}
