.product-header {
}

.product-header__top-section {
  background-color: $skeleton-background;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 5rem 0;
  position: relative;
  overflow-y: hidden;

  .btn {
    background-color: rgba(255, 255, 255, 0.61);
    border-color: transparent;
  }
}

.product-header__other-references {
}

.product-header__tooltip-box {
  width: 350px;
  height: 170px;
  line-height: 140%;
  background-color: #ffffff;
  font-size: 0.79rem;
}