.testimonial-modal--container-modal .modal-content {
  width: 90% !important;
}

.testimonial-modal--container-img {
  height: 350px;
  overflow: hidden;
  border-radius: 10px;
}

.testimonial-modal--big-img {
  border-radius: 20px;
  object-fit: cover;
  aspect-ratio: 9/12;
  object-position: top;
  width: 100%;
  height: 100%;
}

.testimonial-modal--header {
  border-bottom: none !important;
  padding: 0 !important;
}

.testimonial-modal--description {
  margin-top: 1.25rem;
  margin-bottom: 20px;
}

.testimonial-modal--description h2 {
  font-size: 1.125rem;  
  font-family: $font-family-sans-serif;
  font-weight: bold;
}

.testimonial-modal--description h3 {
  font-size: 0.875rem;  
  font-family: $font-family-sans-serif;
  font-weight: normal;
}