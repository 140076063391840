body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, .h1,
h2, .h2 {
  font-family: $headings-font-family;
}
