@include media-breakpoint-up(lg) {
  .contact-page-hero {
    height: 0;
    padding-bottom: 35%;
  }

  .contact-us-page__contact-box {
    margin-top: -6rem;
  }
}
