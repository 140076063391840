@include media-breakpoint-up(lg) {
  .our-essence--poster-container {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .our-essence--poster-icon-play {
    position: absolute;
    top: 47%;
    left: 46%;
    cursor: pointer;
    width: 81px;
    height: 81px;
  }

  .our-essence--video-container {
    height: 700px;
  }
}