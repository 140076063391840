.store-info-window {
  max-width: 250px;
}

.store-info-window__header {
  padding: .5rem 1rem;
  background-color: $cyan-darker;
  color: white;
  font-weight: $font-weight-bold;
  margin: 0;
  padding-right: 30px; // space for the close button
  font-size: $font-size-base;
}

.store-info-window__body {
  padding: 1rem;

  a {
    color: black;
  }
}
