@include media-breakpoint-down(md) {
  .header-dropdown {
    display: none;
    background-color: $light-grayish-yellow;
  }

  .header-dropdown--open {
    display: block;
  }

  .header-dropdown__link {
    border-bottom: 1px solid #F0F0F0;

    a {
      display: block;
      padding: $header-menu-button-padding-sm;
      font-weight: $font-weight-medium;
      color: $gray;
    }
  }
}