.project-card {
  background-color: $light-grayish-yellow;
}

.project-card__image {
  height: 0;
  padding-bottom: 63.4%;
  background-color: $skeleton-background;
  position: relative;

  img {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.project-card__details {
  h3, h4, p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}