.header-burger {
  display: flex;
  height: 100%;
  align-items: center;
}

.burger-lines {
  width: 28px;
}

.burger-lines__line {
  display: block;
  width: 100%;
  height: 1px;
  margin-bottom: 9px;
  background-color: white;

  &:last-child {
    margin-bottom: 0;
  }
}