@media (min-width: 1700px) {
  .upper-slider-container {
    height: 700px;
  }

  .slider1-bg-svg {
    width: 30%;
    height: 30%;
    bottom: 74%;
    left: -1%;
    background-size: contain;
  }

  .slider2-bg-svg {
    left: 0%;
    height: 100%;
    width: 100%;
    bottom: 10%;
    background-size: contain;
    z-index: -1;
  }

  .slider3-bg-svg {
    left: 3%;
    bottom: 30%;
    background-size: contain;
    height: 100%;
    width: 100%;
  }
}

@media (min-width: 2200px) and (max-width: 2499px) {
  .upper-slider-container {
    height: 900px;
  }

  .slider2-bg-svg {
    left: 0%;
    height: 100%;
    width: 100%;
    bottom: 10%;
    background-size: contain;
    z-index: -1;
  }

  .slider3-bg-svg {
    left: 3%;
    bottom: 40%;
    background-size: contain;
    height: 100%;
    width: 100%;
  }
}

@media (min-width: 2500px) {
  .upper-slider-container {
    height: 900px;
  }

  .slider2-bg-svg {
    left: 0%;
    height: 100%;
    width: 100%;
    bottom: 10%;
    background-size: contain;
    z-index: -1;
  }

  .slider3-bg-svg {
    left: 3%;
    bottom: 30%;
    background-size: contain;
    height: 100%;
    width: 100%;
  }
}

@media (min-width: 2560px) {
  .slider2-bg-svg {
    left: 0%;
    height: 70%;
    width: 70%;
    bottom: 20% !important;
    background-size: contain;
    z-index: -1;
  }

  .slider3-bg-svg {
    left: 3%;
    bottom: 35%;
    background-size: contain;
    height: 60%;
    width: 60%;
  }
}
