.sign-up-form {
}

.sign-up-success {
}

.sign-up-success__icon {
  font-size: $h1-font-size * 4;
  color: $cyan-darker;
}
