.sign-in-form {
  .sign-in-form__privacy-policy {
    input, [type=file] + label.file-input-label {
      width: inherit;
    }

    label > span {
      margin-left: 10px;
    }
  }
}