.sub-hero-content {
  position: relative;
  overflow-x: hidden;
  padding-top: $sub-hero-shape-height;
  margin-top: -$sub-hero-shape-height;

  &:after {
    content: "";
    display: block;
    width: 162px;
    height: 121px;
    position: absolute;
    right: $sub-hero-shape-width * -.3;
    top: 0;
    mask: url("../img/courve.svg");
    mask-size: cover;
  }
}

.sub-hero-content--hide-shape {
  padding-top: 0;
  margin-top: 0;

  &:after {
    display: none;
  }
}

.sub-hero-content {
  @include colorize-sub-hero-content(#FCD8E0, black);
}

.sub-hero-content--white {
  @include colorize-sub-hero-content(white, black);
}

.sub-hero-content--green {
  @include colorize-sub-hero-content(#046e70, white);
}

.sub-hero-content--green-dark {
  @include colorize-sub-hero-content($cyan-darker, white);
}

.sub-hero-content--red {
  @include colorize-sub-hero-content(#964B2D, white);
}

.sub-hero-content--purple {
  @include colorize-sub-hero-content(#4d0061, white);
}

.sub-hero-content--prussian-blue {
  @include colorize-sub-hero-content($prussian-blue, white);
}