.logo {
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.default {
  left: 50%;
}

.reversed {
  left: 55%;
}

