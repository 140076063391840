@media only screen and (min-width: 1442px) and (min-width: 1900px) {
  .home-featured-project {
    height: 400px;
  }

  .text-margin-xl {
    margin-top: 40px;
  }

  .home-featured-project-image {

    img {
      width: 100%;
      height: 100%;
    }
  }
}

@media only screen and (min-width: 1900px) {
  .home-featured-project {
    height: 530px;
  }

  .padding-xl {
    padding-top: 40px !important;
  }

  .text-margin-xl {
    margin-top: 60px;
  }

  .text-size-xl-h1 {
    font-size: 3rem;
  }

  .text-size-xl-h2 {
    font-size: 1.4rem;
  }

  .text-size-xl-h3 {
    font-size: 1.2rem;
  }

  .home-featured-project-image {
    img {
      width: 100%;
      height: 100%;
    }
  }
}