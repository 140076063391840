.transform-lifes--slider {
  height: 400px;
}

.transform-lifes--slider > .slick-list > .slick-track > .slick-slide > div {
  text-align: center;
}

.transform-lifes--slider > .slick-list, .slick-track, .slick-slide {
  height: 100%;
}

.transform-lifes--slider > .slick-list > .slick-track > .slick-slide > div {
  height: 100%;
}

.transform-lifes--slider-item {
  border-radius: 20px;
}

.transform-lifes--slider > .slick-dots > li {
  width: 20px;
}

.transform-lifes--slider > .slick-dots {
  position: relative;
}

.transform-lifes--slider > .slick-dots li button::before {
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  border-radius: 20px;
  top: 0;
  left: 0;
  width: 6px;
  height: 6px;
  content: "";
  text-align: center;
  opacity: 0.25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #595959;
}

.transform-lifes--slider > .slick-dots > .slick-active button::before {
  opacity: 1;
  background-color: #000;
}

.transform-lifes--slider > .slick-next::before {
  content: "";
}

.transform-lifes--slider > .slick-prev {
  left: -32px;
}

.transform-lifes--slider-item {
  width: 330px;
  height: 379px;
  border-radius: 10px;
}

.transform-lifes--img {
  width: 100%;
  height: 379px;
}