.testimonial-slide--description h2 {
  font-size: 1.125rem;
  font-weight: bold;
  font-family: $font-family-sans-serif;
}

.testimonial-slide--description h3 {
  font-weight: normal;
  font-size: 1rem;
}

.testimonial-slide--desc-container {
  width: 90%;
  margin-inline: auto;
  background-color: #5a5e6563 !important;
}

.testimonial-slide--img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.aspect-0\/5 {
  aspect-ratio: .5 !important;
}

.testimonial-slide--content {
  cursor: pointer; 
  height: 384px !important; 
  width: 352px !important;
}

.testimonial--slide-container {
  max-height: 500px;
  height: auto;
}