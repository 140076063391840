.product-specs-accordion__title-higher-purpose {
  margin: 0;
  color: $gray-light;
  padding: 1rem $table-cell-padding;
  font-size: 1.5rem;

  i {
    float: right;
  }
}

.product-specs-accordion__title-higher-purpose-item {
  margin: 0;
  color: $gray-light;
  padding: 1rem $table-cell-padding;
  font-size: 1.125rem;

  i {
    float: right;
  }
}

.product-specs-accordion__collapse-higher-purpose {
  border-top: none;
}

.higher-purpose--title-border {
  border-bottom: $table-border-width solid $table-border-color;
}

.higher-purpose--container-specs {
  height: 69px;
}

.higher-purpose--container-specs-item {
  border-bottom: 2px solid $table-border-color;
  height: 68px;
}

.higher-purpose--icon-item {
  width: 55px;
  height: 55px;
}

.product-specs-table {
  margin: 0;
  table-layout: fixed;
}

.fs-chevron-down {
  font-size: 13px;
}

.fs-sp-minus {
  font-size: 1.5rem;
}