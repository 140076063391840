.request-samples-disclaimer {
  background: #EFEFEF;
  border: 1px solid rgba(0,0,0,0.04);

  p {
    color: $gray-600;
  }
}



