.product-card-name {
  height: 58px;  // 3 lines of text, including <a/>'s padding
  display: flex;
  align-items: center;

  h2 {
    margin: 0;
    font-family: $font-family-sans-serif;

    a {
      display: block;
      font-size: $font-size-sm * .85;
      padding: $grid-small-gutter-width * 0.5;
      color: $dark;
    }
  }
}
