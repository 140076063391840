
.main-banner-profile {
    height: 350px;
    background-color: #E3E4E4;
}

.divider {
    border-right: 2px solid black;
    height: auto;
}

.divider-orders {
    border-right: 2px solid black;
    height: 30px;
    position: relative;
    top: -8px;
}

.list-style {
    list-style-type: none;
}

.banner-text {
    margin-bottom: 11px;
}

.pointer {
    cursor: pointer;
}