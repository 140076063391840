@use "sass:math";

@include media-breakpoint-up(lg) {
  .header-market-segment {
    position: relative;
    padding: 0 10px;

    &:before {
      content: "";
      display: block;
      width: 1px;
      height: 126px;
      position: absolute;
      left: -1px;
      top: 0;
      background-color: #EAEAEA;
    }

    &:first-child {
      &:before {
        display: none;
      }
    }
  }

  @for $i from 1 through 7 {
    // This selector is for a div outside this component, re-think how this should be done
    // in order to have everything on the right place
    .products-dropdown-markets--has-#{$i}-children {
      .header-market-segment {
        width: math.div(100%, $i);
      }
    }
  }

  .header-market-segment__title {
    color: $very-dark-cyan;
    font-size: $font-size-xs;
    font-weight: $font-weight-bold;

    i {
      display: none;
    }
  }

  .header-market-segment__title--marine {
    color: $prussian-blue;
  }

  .header-market-segment__links {
    li {
      a {
        font-size: $font-size-sm;
        color: $gray;
        padding: .2rem 0 .2rem 0;
        width: 100%;
      }
    }
  }
}
