@include media-breakpoint-down(md) {
  .header-main-menu {
    display: none;
    position: fixed;
    padding-bottom: $header-height + $header-cart-height;
    background-color: transparent;
    top: $header-height + $header-cart-height;
    left: 0;
    width: 100%;
    overflow-y: scroll;
    max-height: 100vh;
  }

  .header-main-menu--no-header-cart {
    padding-bottom: $header-height;
    top: $header-height;
  }

  .header-main-menu--open {
    display: block;
  }

  .main-menu-sections {

  }

  .main-menu-sections__section {
    
  }
}