@include media-breakpoint-up(xl) {
  .individual-image--container {
    height: 423px;
    border-radius: 40px;
  }

  .antimicrobial-biocide-comfort-img {
    position: relative;
    left: -50px;
  }
}
