@include media-breakpoint-down(sm) {
  .home-slide {
    height: 70vh;
  }

  .home-slide__content {
    height: 100%;
    position: relative;

    > .container {
      height: 100%;

      > .row {
        height: 100%;

        > [class*="col"] {
          height: 100%;
        }
      }
    }
  }

  .slide-content {
    position: absolute;
    bottom: 0;
    padding: 0 1rem 4rem 1rem;
  }

  .slide-content__title > div > .home-report-2023-title {
    font-size: 1.52rem;
  }
}