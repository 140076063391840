.main-container-banner-home-quickship-page{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // background-color: #f7f6fb76;
  background-color: #FFFFFF;
}

.quickship--banner-container {
  box-sizing: border-box;
  background-color: #f7f6fb76;
  width:100%;

  .responsive-banner-quickship {
    width: 50%;
  }
}

.conatiner--img--home-baner {
  max-width: 100%;

  img {
    max-width: 100%;

    @media (min-width: 1700px) {
      min-width: 100%;
    }
  }
}

.quickship--banner-left-container {
  position: relative;
  
  .conatiner--img--home-baner img {
    mask-image: linear-gradient(#000 85%, #eb0d0d00 100%);
  }
}

.conatiner--content--home-baner {
  display: flex;
  flex-direction: column;
}

.quickship--banner-right-container {
  padding: 70px 0 0 0;
  
  .conatiner--img--home-baner img {
    mask-image: linear-gradient(360deg, #000 85%, #eb0d0d00 100%);
  }
}

.container--andtitle-descriptcion {
  padding: 0 5rem;

  @media (max-width: 1280px) {
    padding: 0 2rem;
  }
}

.quickship--banner-about-title {
  color: #000;
  font-family: "Gramatika";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.quickship--banner-how-works-title {
  color: #000;
  font-family: "Gramatika";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top: 45px;
}

.quickship--banner-description {
  color: #000;
  font-family: "Gramatika";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 20px;
}

.btn-Mobile-home-quickship-page {
  background-color: #000000;
  color: #FFFFFF;
  border: 1px solid #FFFFFF;
  padding: 0.5rem 1rem;
  border-radius: 12px;
  min-width: 147px;
  text-align: center;
  min-height: 47px;
  transition: all 0.3s ease;

  &:hover {
    background-color: #FFFFFF;
    color: #000000;
    border: 1px solid #000000;
    box-shadow: 0px 0px 10px #0000007d;
  }
}

.quickship--banner-btn-arrow-down, 
.quickship--banner-btn-arrow-down a,
.quickship--banner-btn-arrow-down a:hover {
  gap: 1rem;
  color: #000;
  font-family: "Gramatika";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  margin-top: 2rem;

  @media (max-width: 768px) {
    margin: 0;
  }
}

.animationImgArrowDown {
  animation: floatArrow 2s ease-in-out infinite
}

@keyframes floatArrow {
  50% {
      transform: translateY(5px)
  }
}


@media (max-width: 768px) {
  .quickship--banner-container{
    // height: 100vh;
    min-height: auto;
    flex-direction: column;
  }

  .quickship--banner-description {
    font-size: 16px;
    padding-top: 0;
  }

  .container--andtitle-descriptcion {
    padding: 0 1rem;
  }

  .quickship--banner-btn-arrow-down {
    margin-top: 2rem;
  }

  .quickship--banner-left-container {
    // padding: 25% 0 0 0;
  }

  .quickship--banner-right-container {
    padding: 70px 0 0 0;
  }
}


@media (max-width: 990px) {
  .quickship--banner-container{
    // height: 100vh;
    min-height: auto;
    flex-direction: column;
  }

  .responsive-banner-quickship {
    width: 100% !important;
  };

  .conatiner--img--home-baner{
    img {
      min-width: 100%;
    }
  }
}