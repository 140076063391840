.product-details {

}

.product-details-actions {
  .btn {
    text-align: left;
    margin-bottom: 1rem;
    color: $gray;
    border-color: $gray;
    font-weight: $font-weight-bold;
    font-size: $font-size-base;
    padding-top: .75rem;
    padding-bottom: .75rem;

    i.fa {
      float: right;
      margin-top: 2px;
      color: $dark-cyan;
    }
  }
}