.header-menu-button {
  font-weight: $font-weight-medium;
}

.icon {
  display: block;
  text-align: center;
  font-size: $header-menu-button-icon-radius;
  background-color: $white;
  color: $very-dark-cyan;
  padding: 1.25rem;
  border-bottom: 1px solid $header-menu-button-border-color-sm;
}

.icon--marine {
  color: $prussian-blue;
}