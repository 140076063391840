@include media-breakpoint-up(md) {
  .glossary-section--modal-mobile > .modal-dialog > .modal-content {
    border-radius: 20px !important;
    width: 404px;
    margin: 0 auto !important;
  }

  .glossary-section--modal-mobile > .modal-dialog {
    margin: 1.75rem auto;
  }
}