@include media-breakpoint-up(lg) {
  // .transform-lifes--slider .slick-active {
  //   margin-left: 20px;
  // }

  .transform-lifes--slider-item {
    width: 33.3%;
    height: 33.3%;
    border-radius: 10px;
  }
  
  .transform-lifes--img {
    width: 349px;
    height: 379px;
  }
}