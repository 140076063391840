.upper-slider-container {
  height: 450px;
}

.carousel-indicators {
  margin-bottom: 28px;
}

.carousel-control-prev {
  background-image: url("../img/left-chevron.png") !important;
  background-size: 28px 28px;
  background-repeat: no-repeat;
  background-position: 20%;
  background-position-x: 100px;
}

.carousel-control-next {
  background-image: url("../img/right-arrow.png") !important;
  background-size: 28px 28px;
  background-repeat: no-repeat;
  background-position: 20%;
  background-position-x: 100px;
  z-index: 1000;
}

.carousel-control-next-icon {
  display: none !important;
}

.carousel-indicators > button {
  background-color: white;
  border: none;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  margin: 0 10px;
  padding: 0;
}

.custom-indicators .carousel-indicators > button {
  background-color: #c2c2c2;
}

.custom-indicators .carousel-indicators > .active {
  background-color: #727272;
}

.carousel-inner {
  overflow: hidden !important;
}

.slider1-bg-svg {
  position: absolute;
  bottom: 40%;
  left: -45%;
  height: 100%;
  width: 100%;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("../img/upperSlider/11.svg");
  z-index: -1;
}

.slider2-bg-svg {
  position: absolute;
  bottom: 25%;
  left: -30%;
  height: 100%;
  width: 150%;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("../img/upperSlider/2.svg");
  z-index: -1;
}

.slider3-bg-svg {
  position: absolute;
  bottom: 0%;
  left: -28%;
  height: 100%;
  width: 150%;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("../img/upperSlider/3.svg");
  z-index: -1;
}
