@keyframes swipe-move-on {
  from {
    left: 0%;
  }

  to {
    left: 50%;
  }
}

@keyframes swipe-move-off {
  from {
    left: 50%;
  }

  to {
    left: 0%;
  }
}

@keyframes show-glossary {
  from {
    transform: translateY(100vh);
  }

  to {
    transform: translateY(0vh);
  }
}

@keyframes hide-glossary {
  from {
    transform: translateY(0vh);
  }

  to {
    transform: translateY(100vh);
  }
}