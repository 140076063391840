.newsletter-form {
  input {
    background-color: rgba(255, 255, 255, 0.2);
    color: white;
    border-color: transparent;

    &:focus,
    &:active {
      background-color: rgba(255, 255, 255, 0.2);
      outline: none;
      box-shadow: none;
      border-color: transparent;
      color: white;
    }

    &::placeholder {
      color: #A1A1A1;
    }
  }
}