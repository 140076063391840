.header-auth-box {
  position: absolute;
  background-color: white;
  top: $header-height;
  right: 0;
  padding: 1.5rem $grid-gutter-width-half;
  text-align: center;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.15);
  display: none;
  width: 60vw;

  h4 {
    font-size: $font-size-base;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
  }

  p {
    color: $gray-light;
    font-weight: $font-weight-light;
    font-size: $font-size-sm;
  }
}

.header-auth-box--open {
  display: block;
}
