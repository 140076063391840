.modal-service-contact {
  background-color: $gray;
  padding: 1rem;

  .modal-content {
    background-color: $gray;
    border: 0;
  }
}

.modal-service-header {
  background-color: $gray;
  border: 0;

  .close {
    color: $white;
  }
}
