// mockey patch for styles of react-slick

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.slider-cards--masiala {
  .slick-slider {
    .slick-dots {
      li {
        &.slick-active {
          button {
            &:before {
              color: #606060;;
              opacity: 1;
            }
          }
        }
    
        button {
          &:before {
            color: white;
            opacity: .5;
          }
        }
      }
    }
  }

  .slick-list {
    min-height: 300px !important;
    height: auto !important;

    .slick-track {
      .slick-slide > div {
        display: flex !important;
        justify-content: center !important;
      }
    }
  }
}
