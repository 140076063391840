ul.paginator {
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 0 15px;

  .paginator__next-page, .paginator__previous-page, li > a, .paginator__page-separator {
    color: gray;
    text-transform: uppercase;
    font-size: 15px;
    cursor: pointer;
  }

  .paginator__next-page, .paginator__previous-page {
    &.disabled {
      visibility: hidden;
    }
  }

  .paginator__next-page {
    float: right;
    padding-right: 0;
  }

  .paginator__previous-page {
    float: left;
    padding-left: 0;
  }

  li.paginator__page-number--active {
    color: black;
    font-weight: 700;
  }
}
