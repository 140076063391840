@include media-breakpoint-down(md) {
  .header-market-segment {
    border-bottom: 1px solid #F0F0F0;
  }

  .header-market-segment--open {
    background-color: #EDEDEB;
    position: relative;

    .header-market-segment__links {
      display: block;
    }

    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 5px;
      background-color: pink;
      position: absolute;
      left: 0;
      top: 0;
      opacity: .05;
      @include gradient-y(black, transparent);
    }
  }

  .header-market-segment__title {
    color: $gray;
    font-weight: $font-weight-medium;
    font-size: $font-size-base;
    line-height: 1;
    padding: 1rem;
    margin: 0;
  }
  
  .header-market-segment__chevron {
    display: block;
    font-size: $font-size-base * 0.7;
    line-height: 1;
    padding: 1rem;
  }

  .header-market-segment__links {
    display: none;

    li {
      a {
        display: block;
        padding: .3rem 1rem .3rem 1.5rem;
        color: $gray-light;
        font-weight: $font-weight-medium;

        &:hover,
        &:active {
          color: $gray-light;
          text-decoration: none;
        }
      }
    }
  }
}