@include media-breakpoint-up(lg) {
  .header-main-menu {
    @include clearfix();
  }

  .main-menu-sections {
    margin: 0;
    @include clearfix();
    display: inline-block;
    float: left;

    &:nth-child(2) {
      float: right;
      position: relative;
    }
  }

  .main-menu-sections__section {
    display: inline-block;
    float: left;
  }

  .main-menu-sections__section > div:after {
    background: $very-dark-cyan;
    content: "";
    display: block;
    height: $menu-underline-height;
    transform: scaleX(0);
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .main-menu-sections__section--marine > div:after {
    background: $white;
  }

  .main-menu-sections__section:hover > div:after {
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
    transform: scaleX(1);
  }

  .header-auth-search-section {
    &:after {
      content: "";
      display: block;
      width: 1px;
      height: 50%;
      position: absolute;
      left: 12px;
      top: 25%;
      background-color: #e1e1e1;
      opacity: 0.24;
    }
  }
}
