@include media-breakpoint-up(md) {
  .home-featured-project {

  }

  .home-featured-project-image {
    position: absolute;
    width: 50vw;
    height: 100%;
    right: 0;
    top: 0;
    padding-bottom: 0;

    img {
      width: auto;
      height: 100%;
    }
  }
}