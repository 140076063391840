@include media-breakpoint-down(sm) {
  .spglobal-footer {
    padding-top: 3rem;

    a {
      color: white;
    }
  }

  .footer-links--instances {
    a {
      font-size: $font-size-xs;
    }
  }

  .footer-links--social {
    a {
      width: 25%;
      display: inline-block;
      padding: 2px 0;
    }
  }
}
