@include media-breakpoint-down(sm) {
  .characteristic-details--modal-mobile > .modal-dialog > .modal-content {
    border-radius: 20px !important;
    width: 100% !important;
    margin: 0 0 !important;
  }

  .glossary-section--container {
    width: 100% !important;
  }

  // .characteristic-details--modal-mobile > .modal-dialog, .characteristic-details--modal-mobile > .modal-dialog >.modal-content {
  //   height: 100%;
  // }
}