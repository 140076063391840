@include media-breakpoint-down(md) {
  .testimonial-slider--prev-arrow {
    left: -16px;
    width: 30px;
    height: 30px;
  }

  .testimonial-slider--next-arrow {
    width: 30px;
    height: 30px;
    right: -40px;
  }
}