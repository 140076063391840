@include media-breakpoint-up(lg) {
  .spglobal-header {
    > .container {
      > .row {
        // add position relative for the products dropdown to be positioned relative to it
        position: relative;

        > .col-lg-9 {
          // remove position relative so the products dropdown will be positioned relative to the .row
          position: initial;
        }
      }
    }
  }
}