@include media-breakpoint-down(md) {
  .header-products-dropdown {
    background-color: $light-grayish-yellow;
  }

  .header-products-dropdown--open {

  }

  .header-products-dropdown__bottom {
    padding: 1rem 0;
  }

  .header-products-dropdown--disabled {
    color: $white;
    background-color: $gray-light;

    &:hover {
      color: $white;
    }
  }

  .products-dropdown-link {
    color: $gray;
    font-weight: $font-weight-medium;
    padding: .15rem 1rem;

    &:hover,
    &:active,
    &:focus {
      color: $gray;
      text-decoration: none;
    }
  }

  .products-dropdown-link__subtitle {
    display: none;
  }
}