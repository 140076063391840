@include media-breakpoint-up(lg) {
  .sample-contact-box-text {
    text-align: right;
    font-size: 1.25rem;
  }

  .detail-pattern-container {
    width: 75%;
    float: right;
  }
}
