html {
  scroll-behavior: smooth;
}

.spglobal-app {
  padding-top: $header-height + $header-cart-height;
}

.spglobal-app--sm-rc {
  padding-top: $header-height + $header-height-subinstance-usa !important;
}

.spglobal-app--no-header-cart {
  padding-top: $header-height;
}