.blog-post-title {
  &.blog-post-title--img-bg, &.blog-post-title--featured {
    color: white;
  }

  .blog-post-title__heading {
    font-family: "Gramatika", sans-serif;
    margin-top: 8px;
    font-size: 24px;
  }

  &.blog-post-title--featured {
    .blog-post-title__heading {
      font-size: 19px;
      font-weight: $font-weight-medium;
    }
  }

  &.blog-post-title--common {
    .blog-post-title__heading {
      color: $gray;
      font-size: 19px;
      font-weight: $font-weight-medium;
    }
  }
}