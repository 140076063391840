
.common-blog-post-card {
  //height: 100%;
  background: $gray-lighter;
}

.common-blog-post-card__content {
  height: 100%;
}

.common-blog-post-card__photo_skeleton {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 180px;
  border-radius: 0px !important;
}