@include media-breakpoint-up(md) {
  .home-slide {
    padding-bottom: 60%;
  }

  .home-slide__content {
    width: 100%;
    height: auto;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 2;
    padding-bottom: 6rem;
  }

  .slide-content__title {
    font-size: $h1-font-size * 1.5;
  }

  .slide-content__title > div > .home-report-2023-title {
    font-size: 2.5rem;
  }
}