.contact-box {
  background-color: $very-dark-cyan;
  color: white;
}

.contact-box--marine {
  background-color: $prussian-blue;
}

.contact-box--resources {
  background-color: $gray;
}

.contact-box-text {
  text-align: center;
  padding: 2rem 0;
  margin: 0;
  font-size: $font-size-sm;
}
