@include media-breakpoint-up(lg) {
  .header-dropdown {
    display: none;
    visibility: hidden;
    position: absolute;
    background-color: white;
    box-shadow: 0 4px 7px rgba(0,0,0,0.14);

    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 7px;
      position: absolute;
      left: 0;
      top: 0;
      @include gradient-y(black, transparent);
      opacity: .15;
    }
  }

  .header-dropdown--open {
    display: block;
    visibility: visible;
    margin-top: $menu-underline-height;
  }

  .header-dropdown__link {


    a {
      display: block;
      color: $very-dark-cyan;
      font-weight: $font-weight-medium;
      padding: .2rem 2rem;
    }

    &:first-child {
      a {
        padding-top: 1.5rem;
      }
    }

    &:last-child {
      a {
        padding-bottom: 1.5rem;
      }
    }
  }

  .header-dropdown__link--marine {
    a {
      color: $prussian-blue;
    }
  }
}