.btn {
  border-radius: 1.25rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  font-weight: $font-weight-medium;

  i {
    position: relative;
    top: 1px;
  }
}

.btn-lg {
  border-radius: 2rem;
}

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}

// Quick monkey patch for btn-outline-dark because it's hiding text when hovered
.btn-outline-dark {
  &:hover {
    color: white !important;
  }
}

.btn-outline-prussian-blue {
  &:hover {
    color: white !important;
    background-color: $prussian-blue;
    border-color: $prussian-blue;
  }
}

.btn-primary {
  color: white;
}

.btn-dark {
  &:disabled,
  &.disabled {
    opacity: 0.4;
  }
}

.btn-prussian-blue {
  color: $white;
  background-color: $prussian-blue;
  border-color: $prussian-blue;

  &:hover {
    color: $white;
    background-color: $prussian-blue;
    border-color: $prussian-blue;
  }

  &:disabled,
  &.disabled {
    opacity: 0.4;
  }
}

.btn-illuminating-emerald {
  color: $white;
  background-color: $illuminating-emerald;
  border-color: $illuminating-emerald;

  &:hover {
    color: $white;
  }
}

.link-unstyled,
.link-unstyled:link,
.link-unstyled:hover {
  color: inherit;
  text-decoration: inherit;
}
