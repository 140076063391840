// .transform-lifes--slider-hover .slick-active:hover {
//   .transform-lifes--img {
//     width: 100% !important;
//   }

//   &:not(:hover) {
//     width: 100px !important;
//   }

//   aspect-ratio: 16 / 9;
//   width: 580px !important;
//   transition: width 0.3s; /* Para animar la transformación */
//   margin-left: 20px;
//   box-sizing: border-box;
//   cursor: pointer;
// }

.transform-lifes--slider .slick-active {
  margin-left: 16px;
}

.transform-lifes--slider-hover .slick-active:hover {
  margin-left: 16px;
  aspect-ratio: 16 / 9;
  transition: width 0.3s; /* Transición para el cambio de ancho */
  box-sizing: border-box;
  cursor: pointer;
}

.transform-lifes--slider-hover .slick-active:not(:hover) {
  width: 280px !important;
}

.transform-lifes--slider .slick-track {
  width: 6000px !important;
}

.transform-lifes--slider-hover .slick-active .transform-lifes--img {
  width: 100% !important; /* Estilo específico para .transform-lifes--img cuando se hace hover */
}

.transform-lifes--slider {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
