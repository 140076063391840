@include media-breakpoint-up(lg) {
  .characteristic-detail--number {
    height: 60px,;
    width: 70px;
    color: white;
    background-color: #052e60;
    position: relative;
    top: -17px;
    right: 15px;
    font-size: 20px;
  }
  
  .characteristic-detail--header {
    border-radius: 20px;
  }
}