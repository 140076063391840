.manufatory-img {
  width: 100%;
  height: 280px;
  background-color: $gray;
}

.manufatory-label {
  position: absolute;
  bottom: 0;
  padding: 0.5rem;
  margin: 0;
  font-weight: bold;
  color: $white;
  background-color: rgba(216, 216, 216, 0.5);
}
