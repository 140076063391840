@include media-breakpoint-down(sm) {
  .product-specs-accordion__title-higher-purpose {
    border-bottom: $table-border-width solid $table-border-color;
    margin: 0;
    color: $gray-light;
    padding: 1rem $table-cell-padding;
    font-size: $h5-font-size;

    i {
      float: right;
    }
  }
}