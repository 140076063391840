@include media-breakpoint-up(lg) {
  .product-card-name--in-compare {
    height: 58px; // 3 lines of text, including <a/>'s padding

    h2 {
      a {
        padding-left: 0;
        padding-right: 0;
        font-size: $font-size-sm * .85;
      }
    }
  }
}