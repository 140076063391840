@include media-breakpoint-up(md) {
  .reference-card {

  }

  .reference-card__image {
    width: auto;
  }

  .reference-card__name {
    h4 {
      overflow-x: hidden;
    }
  }
}