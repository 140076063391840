@include media-breakpoint-down(sm) {
  .references-carousel {
    overflow: hidden;
  }

  .references-carousel-content {
    flex-wrap: nowrap;
    overflow-x: scroll;
    box-sizing: content-box;
    padding-bottom: .5rem;
  }

  .references-carousel-content__card {
    flex-basis: $carousel-reference-width;
    min-width: $carousel-reference-width;
    max-width: $carousel-reference-width;
    margin-right: $grid-gutter-width-half;
    height: auto;

    &:first-child {
      margin-left: $grid-gutter-width-half;
    }

    &:last-child {
      box-sizing: content-box;
      padding-right: $grid-gutter-width-half;
    }
  }
}