.social-share-nav {
  .nav-item {
    &:last-child {
      .nav-link {
        padding-right: 0;
      }
    }
  }

  .nav-link {
    padding-left: .4rem;
    padding-right: .4rem;
    color: $gray;

    i {
      font-size: $font-size-lg;
    }
  }
}

.social-share-nav__title {
  .nav-link {
    padding-right: 1rem;
    color: $gray-light;
  }
}
