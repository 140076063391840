.image-hotspot--container {
  height: 400px;
  overflow: visible !important;
}

.image-hotspot--container > div {
  overflow: visible !important;
}

.image-hotspot--container > div > div > div {
  background-color: transparent !important;
  box-shadow: none !important;
}

.hotspot-point {
  background-color: #052e60;
  position: relative;
  color: white;
  right: 18px;
  width: 27px;
  height: 27px;
  font-size: 18px;
  font-family: $font-family-sans-serif;
  cursor: pointer;
  z-index: 400;
}