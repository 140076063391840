@include media-breakpoint-down(md) {
  .header-subinstances-menu-button {
    position: relative;
    display: block;
    width: 100%;
    background-color: transparent;
    color: $very-dark-cyan;
    border: none;
    margin: 0.5rem 0;
    padding: $header-menu-button-padding;
    font-size: $font-size-base;
    text-align: left;

    &:hover,
    &:focus,
    &:active {
      outline: none;
      text-decoration: none;
      box-shadow: none;
      color: $white;
      font-weight: 600;
    }
  }
}
