@include media-breakpoint-up(md) {
  .header-auth-box {
    width: 500px;
    text-align: left;
    padding: 2rem 1.5rem;

    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 7px;
      position: absolute;
      left: 0;
      top: 0;
      @include gradient-y(black, transparent);
      opacity: .15;
    }

    > .row {
      > [class*="col"] {
        &:first-child {
          &:after {
            content: "";
            display: block;
            width: 1px;
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
            background-color: $auth-box-separator-color;
          }
        }

        &:last-child {
          padding-left: $grid-gutter-width;
        }
      }
    }
  }

  .header-auth-box--authenticated {
    width: 400px;
    padding-bottom: 1rem;
  }

  .auth-box-link {
    color: $gray;
    font-size: $font-size-lg;

    &:hover {
      text-decoration: none;
    }
  }
}
