.transition-transform {
  transition-property: transform;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
}

.duration-300 {
  transition-duration: .3s;
}

.aspect-square {
  aspect-ratio: 1 / 1;
}

.aspect-video {
  aspect-ratio: 16 / 9;
}

.aspect-video-vertical {
  aspect-ratio: 9 / 13;
}