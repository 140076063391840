@include media-breakpoint-up(md) {
  .individual-image--container {
    height: 320px;
    border-radius: 40px;
  }

  .antimicrobial-biocide-comfort-img {
    height: 105%;
  }
}
