.reference-card {
  cursor: pointer;
}

.reference-card--active {
  .reference-card__image {
    border: 2px solid white;
    box-shadow: 0 0 10px rgba(0, 0, 0, .25);
  }
}

.reference-card__image {
  width: 100%;
  height: 90px;
  background-color: $skeleton-background;
}

.reference-card__name {
  width: 100%;
  overflow-x: hidden;
  padding: $grid-small-gutter-width * 0.5 0;

  h4 {
    margin: 0;
    font-size: $font-size-sm * 0.9;
    white-space: nowrap;
  }

  p {
    font-size: $font-size-sm * 0.75;
  }
}