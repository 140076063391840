.header-search-bar {
  width: 100vw;
  background-color: black;
  position: absolute;
  top: $header-height + $header-cart-height;
  left: 0;
  padding: 1rem 0;
  display: none;

  form {
    margin: 0;
  }

  .form-group {
    margin: 0;
  }

  .form-control {
    height: $header-search-input-height;
    background-color: rgba(255, 255, 255, 0.76);
    border-radius: $header-search-input-height;
    padding-left: $header-search-input-height * 0.5;
    color: black;
    font-weight: $font-weight-medium;

    &::placeholder {
      color: #4a4a4a;
    }
  }
}

.header-search-bar--open {
  display: block;
}

.header-search-bar--no-header-cart {
  top: $header-height;
}

.header-search-bar--marine {
  background-color: $prussian-blue;
}
