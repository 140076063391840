.home-slider {
  overflow: hidden;
}

.home-slider .slick-dots {
  bottom: 0;

  li {
    &.slick-active {
      button {
        &:before {
          color: white;
          opacity: 1;
        }
      }
    }

    button {
      &:before {
        color: white;
        opacity: .5;
      }
    }
  }
}