@include media-breakpoint-up(lg) {
  .testimonial-modal--container-img {
    height: 400px;
    overflow: hidden;
    display: flex;
    align-items: start;
    justify-content: center;
    max-width: 800px !important;
  }

  .testimonial-modal--big-img {
    aspect-ratio: 16/12;
    object-fit: cover;
    object-position: center;
  }

  .custom-aspect-ratio {
    aspect-ratio: 16/16 !important;
    width: auto;
    height: auto;
  }

  .custom-rachel-aspect-ratio--img {
    aspect-ratio: 16/18 !important;
    width: auto;
    height: auto;
  }

  .testimonial-modal--container-modal .modal-content {
    width: auto !important;
    height: auto !important;
    max-width: 800px !important;
    padding-bottom: 1rem;
  }
}