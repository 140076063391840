@include media-breakpoint-down(sm) {
  .sticky-filters-bar {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: -100%;
  }

  .sticky-filters-bar--open {
    z-index: $zindex-fixed;
  }

  .filters-bar {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: $zindex-fixed;
    top: 0;
    left: -100%;
    padding: 1rem;
    overflow-y: scroll;
    @include transition(left ease-out 200ms);
  }

  .filters-bar--open {
    left: 0;
  }

  .filters-bar-title {
    display: none;
  }
}