.bio-container {
  position: relative;
  padding: 0;
  margin: 0;
}

.bio-container-fst-color {
  position: absolute;
  background-color: #00866f;
  height: 50%;
  width: 100%;
  padding: 0;
  margin: 0;
}

.bio-title {
  padding-top: 56px;
}

.bio-text {
  /* font-family: "Gramatika", sans-serif; */
  color: white;
  padding: 0 16px;
}

.bio-text-title {
  font-weight: 600 !important;
}

.bio-content {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}
.bio-content_text {
  font-size: 18px;
  font-weight: 400;
  text-align: center;
}

.bio-image-container {
  width: 100%;
  height: 32vh;
  overflow: hidden;
}

.bio-card-container {
  border: 1px solid #d9d9d9;
  padding: 0 !important;
  margin: 0 !important;
  background-color: #ffffff;
}

.bio-section-modal--btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: .25em .25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: .25rem;
  opacity: .5;
}

.bio-section-modal--header {
  background-color: #f9f8f5;
}