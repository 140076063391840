.tag-instance-content {
  display: block;
  text-transform: uppercase;
  font-weight: $font-weight-medium;
  color: white;
  font-size: $tag-instance-font-size;

  &.tag-instance-content--dark {
    color: $gray-light;
  }
}