.featured-blog-post-card {
  display: flex;
  align-items: flex-start;
  background: $cyan-darker;
  padding: 0;
  height: 100%;
  
  .featured-blog-post-card__content {
    display: flex;
    align-content: space-evenly;
    flex-wrap: wrap;
  }

  .featured-blog-post-card__photo {
    height: 100%;
    
    img {
      object-fit: cover;
      min-height: 100%;
    }
  }

  .featured-blog-post-card__description-text {
    color: white;
  }
}

.featured-blog-post-card__photo_skeleton {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  max-width: 100%;
  height: 100%;
  border-radius: 0px !important;
}

.featured-blog-post-card--marine {
  background: $prussian-blue;
}
