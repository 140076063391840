.fw-article {

}

.fw-article-background {
  background: $skeleton-background no-repeat center center;
  background-size: cover;
}

.fw-article-content {
  padding: 1.5rem 0;

  p {
    color: $gray;
    font-size: $font-size-sm;
  }
}
