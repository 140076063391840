@include media-breakpoint-down(md) {
  .header-menu-button {
    background-color: $white;
    border: none;
    text-align: left;
    margin: 0;

    display: block;
    color: $very-dark-cyan;
    width: 100%;
    padding: $header-menu-button-padding-sm;
    position: relative;
    font-size: $font-size-lg;
    border-bottom: 1px solid $header-menu-button-border-color-sm;

    &:hover,
    &:focus,
    &:active {
      outline: none;
      text-decoration: none;
      color: $very-dark-cyan;
      box-shadow: none;
    }
  }

  .header-menu-button--marine {
    color: $prussian-blue;

    &:hover,
    &:focus,
    &:active {
      color: $prussian-blue;
    }
  }
}