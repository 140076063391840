@include media-breakpoint-up(md) {
  .header-products-dropdown {
    width: 100%;
  }

  .header-products-dropdown__bottom {
    > .row {
      > [class*="col"] {
        &:before {
          content: "";
          display: block;
          width: 1px;
          height: 100%;
          background-color: #EAEAEA;
          position: absolute;
          left: 0;
          top: 0;
        }

        &:first-child {
          &:before {
            display: none;
          }
        }
      }
    }
  }
}