.document-list {

  .document-category__body {
    padding: 1.5rem;
    background-color: $gray-lighter;

    .document-category__title {
      font-size: 20px;
      font-weight: 500;
    }

    .document-category__description {
      font-size: 14px;
      font-weight: $font-weight-light;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-height: $description-text-line-height;     /* fallback */
      max-height: $description-text-line-height * $description-text-lines;      /* fallback */
      -webkit-line-clamp: $description-text-lines; /* number of lines to show */
      -webkit-box-orient: vertical;
    }

    .document-category__show-more-link {
      color: $gray-light;

      i {
        font-size: 0.8rem;
        margin-left: 10px;
      }
    }
  }
}