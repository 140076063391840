@include media-breakpoint-up(md) {
  .banner-text {
    margin-bottom: 6px;
  }

  .underline-hover {
    display: inline-block;
  }

  .underline-hover:after {
    background: black;
    content: "";
    display: block;
    height: $menu-underline-height;
    transform: scaleX(0) translateX(5px) translateY(-5px);
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .underline-hover:hover::after {
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
    transform: scaleX(65%) translateX(5px) translateY(-5px);
  }

  .active-account-option::after {
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
    transform: scaleX(65%) translateX(5px) translateY(-5px);
  }

  .active-history-orders-option::after {
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
    transform: scaleX(65%) translateX(5px) translateY(-5px);
  }
}