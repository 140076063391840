.container-data-policy-and-terms {
  height: 550px;
}

.title-underlined::after {
  bottom: 0;
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  width: 100px;
}

.title-underlined--align-center::after {
  left: 50%;
  margin-left: -50px;
}
