@include media-breakpoint-up(lg) {
  .testimonial-slider--container {
    height: fit-content;
    overflow-x: visible;
  }

  .lg-w-100 {
    width: 100%;
  }

  .lg-left-4 {
    left: 1rem;
  }

  .custom-class-width-slider {
    display: flex;
    box-sizing: border-box;
    max-width: 100% !important;
    // transition: all 0.3s ease;
  }

  .heigth500 {
    max-height: 500px;
  }

  .hover-w-50:hover {
    width: 50% !important;
    height: 100%;
  }
}